Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.getHelpCenterByMethod = 'GET';
exports.postHelpCenterByMethod = 'POST';
exports.updateHelpCenterByMethod = 'PATCH';
exports.deleteHelpCenterByMethod = 'DELETE';
exports.helpCenterByIdApiContentType = 'application/json';
exports.getHelpCenterByIdUrl = 'bx_block_content_management/help_centers/';
  // Customizable Area End