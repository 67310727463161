import React from 'react';
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface Props {
  value: string;
  onChange: (value: string) => void;
}
interface S {
  editorState: EditorState;
}
interface SS { }

export default class CustomEditor extends React.Component<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    const contentBlock = htmlToDraft(this.props.value);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    this.state = {
      editorState: EditorState.createWithContent(contentState),
    };
  }

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({ editorState }, () => {
      this.props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    });
  }

  render() {
    return (
      <Wrapper>
        <Editor
          toolbarOnFocus
          placeholder='Write here'
          editorState={this.state.editorState}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: ['history', 'fontFamily', 'fontSize', 'textAlign', 'colorPicker', 'inline', 'list'],
            fontSize: {
              options: [14, 16, 18, 20, 24],
            },
            fontFamily: {
              options: ['Crimson Text', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Heebo-Regular'],
            },
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            textAlign: {
              inDropdown: true,
              options: ['left', 'center', 'right', 'justify'],
            },
            list: {
              options: ['unordered', 'ordered'],
            },
          }}
        />
      </Wrapper>
    )
  }
}

const Wrapper = styled(Box)({
  backgroundColor: "white",
  position: "relative",
  ".public-DraftStyleDefault-block": {
    margin: "0px",
    marginBottom: "10px"
  },
  "& .rdw-editor-main": {
    height: "fit-content",
    borderBottom: "1.5px solid #D6D3D1",
  },
  "& .rdw-editor-toolbar": {
    width: "fit-content",
    position: "absolute",
    top: "-65px",
    border: "1.5px solid black",
  },
  "& .rdw-inline-wrapper": {
    "& .rdw-option-wrapper": {
      height: "20px",
      width: "20px",
      padding: "4px",
      margin: "0px 2px",
      borderRadius: "4px",
    }
  },
});