import React from "react";

// Customizable Area Start
import {
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Button,
} from "@mui/material";
import {
    StyledEngineProvider,
    styled
} from '@mui/material/styles';
import {
    imgDashboard,
    imgCourses,
    imgPackages,
    imgContentManagement,
    imgPhone,
    imgUser,
    imgClients,
    imgAnalytics,
    imgThinkspikeLogo,
    imgLogout,
    imgChevronRight,
    imgRightArrow
} from './assets';

import HtmlReactParser from 'html-react-parser';
import Loader from '../../../components/src/Loader.web';
import HelpCenterAdminPart from "./HelpCenterAdminPart.web";
import { EditorContent } from '@tiptap/react';

interface MainPageItems {
    id: number,
    title: string,
    description: string,
    changePage: () => void
}

import TermsConditionsController, {
    Props,
    configJSON,
    ITermsConds,
} from "./TermsAndConditionsController";
import EditorModal from "./Editor/EditorModal.web";
// Customizable Area End

export default class TermsConditions extends TermsConditionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderEditorPrivacyPolicySection = () => {
        return (
          <>
            {this.state.activeEditor === 'privacyPolicy' && (
              <EditorModal
                editor={this.editorPPText}
                isEditing={this.state.isEditing}
                toolbarPosition={this.state.toolbarPosition}
                toolbarRef={this.editorRef}
              />
            )}
            <div ref={this.editorPPRef}>
              <StyledEditorTitle>
                <EditorContent editor={this.editorPPText} />
              </StyledEditorTitle>
            </div>
          </>
        );
      };

      renderEditorTermsCondsSection = () => {
        return (
          <>
            {this.state.activeEditor === "termsAndCondition" && (
              <EditorModal
                editor={this.editorTACText}
                isEditing={this.state.isEditing}
                toolbarPosition={this.state.toolbarPosition}
                toolbarRef={this.editorRef}
              />
            )}
            <div ref={this.editorTACRef}>
              <StyledEditorTitle>
                <EditorContent editor={this.editorTACText} />
              </StyledEditorTitle>
            </div>
          </>
        );
      };

    renderMainPage = () => {
        const items: MainPageItems[] = [
            { id: 1, title: "Admin sign up email", description: "Review and assign courses and packages", changePage: () => this.handleViewChange("adminEmail") },
            { id: 2, title: "Terms and conditions", description: "Edit text", changePage: () => this.handleViewChange("TermsAndConditions") },
            { id: 3, title: "Privacy policy", description: "Edit text", changePage: () => this.handleViewChange("privacyPolicy") },
            { id: 4, title: "Help centre content", description: "You can customize visual look of FAQ’s", changePage: () => this.handleViewChange("helpCentre") },
        ];

        return (
            <Box data-test-id="main-page-items" sx={styles.listElements}>
                {items.map(({ id, title, description, changePage }) =>
                    <Box data-test-id={`main-page-item-${title}`} onClick={changePage} key={id} sx={styles.listBlock}>
                        <Box sx={styles.blockLeft}>
                            <Box sx={styles.listBlockTitle}>{title}</Box>
                            <Box sx={styles.listBlockDesc}>{description}</Box>
                        </Box>
                        <Box sx={styles.blockRight}>
                            <img src={imgRightArrow}></img>
                        </Box>
                    </Box>
                )}
            </Box>
        )
    }

    renderTermsAndConditionsPage = () => {
        return (
            <Box data-test-id="terms-and-conditions-page" sx={styles.block}>
                <Box sx={styles.blockContent}>
                    <Box sx={styles.blockTitle}>
                        Terms and conditions
                    </Box>
                    <Box sx={styles.textContent}>
                        {this.renderEditorTermsCondsSection()}
                    </Box>
                </Box>
            </Box>
        )
    }

    renderPrivacyPolicyPage = () => {
        return (
            <Box data-test-id="privacy-policy-page" sx={styles.block}>
                <Box sx={styles.blockContent}>
                    <Box sx={styles.blockTitle}>
                        Privacy policy
                    </Box>
                    <Box sx={styles.textContent}>
                        {this.renderEditorPrivacyPolicySection()}
                    </Box>
                </Box>
            </Box>
        )
    }

    renderHelpCenter = () => {
        return (
            <HelpCenterAdminPart
                isSaveDisabled={this.state.isSaveDisabled}
                onSetSaveDisabled={this.updateSaveDisabled}
                onUpdateLoading={this.updateLoading}
                saveRef={this.saveRef}
            />
        );
    }

    definePageText = () => {
        switch (this.state.currentView) {
            case "TermsAndConditions":
                return <>
                    <img src={imgChevronRight} alt="rightangle" />
                    <Box sx={styles.currentNavigationPage}>Terms And Conditions</Box>
                </>
            case "privacyPolicy":
                return <>
                    <img src={imgChevronRight} alt="rightangle" />
                    <Box sx={styles.currentNavigationPage}>Privacy policy</Box>
                </>
            case "adminEmail":
                return <>
                    <img src={imgChevronRight} alt="rightangle" />
                    <Box sx={styles.currentNavigationPage}>Admin email sign up</Box>
                </>
            case "helpCentre":
                return <>
                    <img src={imgChevronRight} alt="rightangle" />
                    <Box sx={styles.currentNavigationPage}>Help Centre</Box>
                </>
            default:
                break;
        }
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const isSaveDisabled = this.state.currentView === 'helpCentre' ? this.state.isSaveDisabled : false;
        return (
            <>
                <Loader loading={this.state.loading}></Loader>
                <Wrapper data-testid="mainWrapper">
                    <Box sx={styles.mainBlock}>
                        <Box sx={styles.sideBar}>
                            <Box sx={styles.sideBarTop}>
                                <Box>
                                    <img src={imgThinkspikeLogo} alt="thinks_logo" />
                                </Box>
                                <List sx={styles.sideNavList}>
                                    <ListItemButton sx={styles.sideNavItem} color="inherit">
                                        <ListItemIcon sx={styles.iconsStyle}>
                                            <img src={imgDashboard} alt="dashBoard" />
                                        </ListItemIcon>

                                        <ListItemText sx={styles.textRow} primary="Dashboard" />
                                    </ListItemButton>

                                    <ListItemButton sx={styles.sideNavItem}>
                                        <ListItemIcon sx={styles.iconsStyle}>
                                            <img src={imgCourses} alt="courses" />
                                        </ListItemIcon>

                                        <ListItemText sx={styles.textRow} primary="Courses" />
                                    </ListItemButton>

                                    <ListItemButton sx={styles.sideNavItem}>
                                        <ListItemIcon sx={styles.iconsStyle}>
                                            <img src={imgPackages} alt="pkgs" />
                                        </ListItemIcon>

                                        <ListItemText sx={styles.textRow} primary="Packages" />
                                    </ListItemButton>

                                    <ListItemButton sx={{ ...styles.sideNavItem, ...styles.blackButton }}>
                                        <ListItemIcon sx={styles.iconsStyle}>
                                            <img src={imgContentManagement} alt="CM" />
                                        </ListItemIcon>

                                        <ListItemText sx={styles.textRow} primary="Content Managment" />
                                    </ListItemButton>

                                    <ListItemButton sx={styles.sideNavItem}>
                                        <ListItemIcon sx={styles.iconsStyle}>
                                            <img src={imgPhone} alt="phone" />
                                        </ListItemIcon>

                                        <ListItemText sx={styles.textRow} primary="Contact us Queries" />
                                    </ListItemButton>

                                    <ListItemButton sx={styles.sideNavItem}>
                                        <ListItemIcon sx={styles.iconsStyle}>
                                            <img src={imgUser} alt="user" />
                                        </ListItemIcon>

                                        <ListItemText sx={styles.textRow} primary="User management" />
                                    </ListItemButton>

                                    <ListItemButton sx={styles.sideNavItem}>
                                        <ListItemIcon sx={styles.iconsStyle}>
                                            <img src={imgClients} alt="clients" />
                                        </ListItemIcon>

                                        <ListItemText sx={styles.textRow} primary="Clients" />
                                    </ListItemButton>

                                    <ListItemButton sx={styles.sideNavItem}>
                                        <ListItemIcon sx={styles.iconsStyle}>
                                            <img src={imgAnalytics} alt="analytics" />
                                        </ListItemIcon>

                                        <ListItemText sx={styles.textRow} primary="Analytics" />
                                    </ListItemButton>
                                </List>
                            </Box>

                            <Box
                                sx={{ ...flexRow, padding: '4px 16px' }}
                                data-test-id="logoutButton"
                            >
                                <Button sx={styles.logoutButton}>
                                    <img src={imgLogout} alt="logout" />
                                    <span>Logout</span>
                                </Button>
                            </Box>
                        </Box>

                        <Box sx={styles.pageContent} data-testid="pageContent">
                            <Box sx={styles.contentHeader}>
                                <Box sx={styles.navigation}>
                                    <Box data-test-id="content-management-button" onClick={() => this.handleViewChange("main")} sx={styles.breadcrumbButton}>Content Management</Box>
                                    {this.definePageText()}
                                </Box>
                                <SaveButton 
                                    isDisabled={isSaveDisabled}
                                    data-test-id="save-button" 
                                    onClick={this.handleSave}
                                >
                                    Save
                                </SaveButton>
                            </Box>

                            <Box sx={styles.userContent}>
                                {this.state.currentView === "main" && this.renderMainPage()}
                                {this.state.currentView === "TermsAndConditions" && this.renderTermsAndConditionsPage()}
                                {this.state.currentView === "privacyPolicy" && this.renderPrivacyPolicyPage()}
                                {this.state.currentView === 'helpCentre' && this.renderHelpCenter()}
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={styles.footerBlock}>
                        <Box sx={styles.leftFooter}>
                            <Box>Terms and Conditions</Box>
                            <Box>Pravicy Policy</Box>
                            <Box></Box>
                        </Box>

                        <Box>
                            <Box>© 2024 All Rights Reserved</Box>
                        </Box>
                    </Box>
                </Wrapper>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

const SaveButton = styled(Button)<{ isDisabled: boolean }>(({ isDisabled } ) => ({
    background: "#1C1C1C",
    fontFamily: "Heebo",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    padding: "10px 16px 10px 16px",
    borderRadius: "4px",
    textTransform: "none",
    opacity: isDisabled ? '.7' : '1',
    pointerEvents: isDisabled ? 'none' : 'all',
}))

const StyledEditorTitle = styled('div')({
    borderRadius: '8px',
    padding: '10px',
    boxSizing: 'border-box',
    '& .ProseMirror-focused': {
      border: 'none',
      outline: 'none',
    },
    '& .ProseMirror > p': {
      margin: '0px',
    },
  });

const Wrapper = styled(Box)(({ theme }) => ({
    padding: "0 0 72px 0",
    ".editor-container": {
        width: "auto",
        overflow: 'auto',
        maxHeight: "350px",
        position: 'absolute',
        background: '#FFFFFF',
        borderRadius: '4px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        "& img": {
            width: "auto",
            height: "revert-layer",
        }
    }
}));

const styles = {
    mainBlock: {
        display: 'flex',
        backgroundColor: '#f6f6f6',
        flex: 1
    },

    footerBlock: {
        position: "fixed",
        width: "calc(100% - 104px)",
        bottom: 0,
        display: 'flex',
        gap: '24px',
        justifyContent: 'space-between',
        padding: '24px 52px 24px 52px',
        backgroundColor: '#1C1C1C',
        color: '#fff'
    },
    leftFooter: {
        display: 'flex',
        gap: '24px'
    },
    rightFooter: {},

    sideBar: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '30px 16px 24px 40px',
        border: '1px solid #f5f5f5',
        backgroundColor: '#fff'
    },
    sideBarTop: {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
    },
    sideNavList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
    },
    sideNavItem: {
        height: '48px',
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        padding: '4px 16px',
        borderRadius: '8px'
    },
    logoutButton: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        color: 'red',
        flexGrow: '1',
        justifyContent: 'start',
        fontSize: '16px',
        textTransform: 'none'
    },
    pageContent: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
    },
    contentHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px 48px'
    },
    navigation: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '16px'
    },
    currentNavigationPage: {
        fontWeight: '700',
        fontSize: '24px',
        padding: '4px 8px',
        fontFamily: "Heebo",
        lineHeight: "32px",
        letterSpacing: "-0.005em",
    },
    breadcrumbButton: {
        color: "#475569",
        fontSize: '24px',
        fontFamily: "Heebo",
        fontWeight: 400,
        lineHeight: "32px",
        letterSpacing: "-0.005em",
        padding: '4px 8px'
    },
    leftActions: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        fontSize: '24px'
    },
    blackButton: {
        backgroundColor: 'black',
        color: 'white',
        '&:hover': {
            backgroundColor: '#333333',
        },
        textTransform: 'none'
    },
    saveButton: {
        backgroundColor: 'black',
        color: 'white',
        '&:hover': {
            backgroundColor: '#333333',
        },
        height: '44px',
        padding: '10px 16px 6px',
        fontWeight: '700'
    },
    moreButton: {
        color: 'black',
        padding: '10px'
    },

    userContent: {
        padding: "40px"
    },
    iconsStyle: {
        minWidth: '1px'
    },
    textRow: {
        whiteSpace: 'nowrap'
    },
    listElements: {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        alignItems: "center",
        justifyContent: 'flex-start',
    },
    listBlock: {
        width: "740px",
        display: "flex",
        justifyContent: 'space-between',
        alignItems: "center",
        borderBottom: "1px solid #E2E8F0",
        backgroundColor: "#FFFFFF",
        padding: "16px 24px 16px 24px",
        borderRadius: "8px 8px 0px 0px",

    },
    blockLeft: {

    },
    blockRight: {

    },
    listBlockTitle: {
        fontFamily: "Heebo",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#0F172A",
    },
    listBlockDesc: {
        fontFamily: "Heebo",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#334155",
    },
    block: {
        padding: "0px 50px 0px 10px"
    },
    blockContent: {
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "20px 24px 20px 24px"

    },
    blockTitle: {
        color: "#0F172A",
        fontFamily: "Heebo",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.005em",
    },
    textContent: {
        marginTop: "10px",
        fontFamily: "Heebo",
        fontSize: "16px",
        lineHeight: "24px",
        "& img": {
            maxWidth: '100%',
            width: "auto",
            height: "revert-layer",
        }
    }
}

const flexRow = {
    display: 'flex',
    flexDirection: 'row'
}


// Customizable Area End
