import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import FontFamily from '@tiptap/extension-font-family';
import FontSize from 'tiptap-extension-font-size';
import Color from '@tiptap/extension-color';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Highlight from '@tiptap/extension-highlight';
import Strike from '@tiptap/extension-strike';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import TextAlign from '@tiptap/extension-text-align';
import { apiCall } from "../../../components/src/ApiCall.web";
import React, { createRef } from 'react';
import { toast } from "react-toastify";
import { Editor } from '@tiptap/react';
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

export interface ITermsCondsList {
  id: string,
  type: string,
  attributes: {
    id: number,
    description: string
  }
}

interface IUser {
  account_id: number,
  accepted_on: string,
  email: string
}

export interface IPrivacyPolicyData {
  id: string,
  type: string,
  attributes: {
    id: number,
    description: string,
    created_at: string,
    accepted_by: IUser[]
  }
}

export type SelectedPage = "main" | "adminEmail" | "helpCentre" | "TermsAndConditions" | "privacyPolicy"
export type SelectedEditorType = "termsAndConditions" | "privacyPolicy" | "none"

export interface Position {
  top: number,
  left: number
};

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentView: SelectedPage,
  termsCondsList: ITermsCondsList | null
  termsAndConditionsValue: string
  privacyPolicyData: IPrivacyPolicyData | null
  privacyPolicy: string,
  loading: boolean,
  toolbarPosition: Position;
  isEditing: boolean;
  activeEditor: 'privacyPolicy' | 'termsAndCondition' | null;
  isSaveDisabled: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  termsAndConditionsApiCallId: string = "";
  privacyPolicyApiCallId: string = "";
  editorRef: React.RefObject<HTMLDivElement>;
  editorPPText: Editor | null = null;
  editorPPRef = React.createRef<HTMLDivElement>();
  editorTACText: Editor | null = null;
  editorTACRef = React.createRef<HTMLDivElement>();
  saveRef = React.createRef<{ handleSave: () => void }>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentView: "main",
      termsAndConditionsValue: "",
      privacyPolicy: "Privacy",
      termsCondsList: null,
      privacyPolicyData: null,
      loading: false,
      toolbarPosition: { top: 0, left: 0 },
      isEditing: false,
      activeEditor: null,
      isSaveDisabled: true,
      // Customizable Area End
    };
    (this.saveRef as any).current = { handleSave: () => {} };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.initEditors()
    this.editorRef = React.createRef<HTMLDivElement>()
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.updateEditorSize();
    this.getToken();
    // Customizable Area Start
    document.addEventListener('mousedown', this.handleClick);
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.isEditing !== this.state.isEditing) {
      this.updateEditorSize();
    }
  }

  updateEditorSize = () => {
    let textBlock = {height: 0, width: 0, left: 0, top: 0};
    if (this.editorRef.current) {
      const { width, height, top, left } = this.editorRef.current.getBoundingClientRect();
      if (this.state.activeEditor === "privacyPolicy" && this.editorPPRef.current) {
        textBlock = this.editorPPRef.current.getBoundingClientRect();
      } else if (this.state.activeEditor === "termsAndCondition" && this.editorTACRef.current) {
        textBlock = this.editorTACRef.current.getBoundingClientRect();
      }

      const windowWidth = textBlock.left + textBlock.width;
      const windowHeight = textBlock.top + textBlock.height;
      const defaultIndentation = 30


      let adjustedTop = top + window.scrollY;
      let adjustedLeft = left;

      if (top + height > windowHeight) {
        adjustedTop = textBlock.height + 100 + defaultIndentation;
      } else if (top < 0) {
        adjustedTop = defaultIndentation;
      }

      if (left + width > windowWidth) {
        adjustedLeft = windowWidth - width - defaultIndentation;
      } else if (left < 0) {
        adjustedLeft = defaultIndentation;
      }

      this.setState({
        toolbarPosition: {
          top: adjustedTop,
          left: adjustedLeft,
        }
      })
    }
  };

  initEditors() {
    this.editorPPText = new Editor({
      extensions: [
        StarterKit.configure({ heading: { levels: [1] } }),
        TextStyle,
        FontFamily,
        FontSize,
        Color,
        Highlight,
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList,
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
      ],
      content: '',
      onUpdate: ({ editor }) => {
        const html = editor.getHTML();
        this.setState({ privacyPolicy: html })
      },
      onSelectionUpdate: ({ editor }) => {
        this.setState(prev => ({ ...prev, activeEditor: "privacyPolicy" }));
        this.updateToolbarPosition(editor);
      },
      onBlur: ({ editor }) => {
        this.handleTitleBlur(editor);
      }
    });

    this.editorTACText = new Editor({
      extensions: [
        StarterKit.configure({ heading: { levels: [1] } }),
        TextStyle,
        FontFamily,
        FontSize,
        Color,
        Highlight,
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList,
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
      ],
      content: '',
      onUpdate: ({ editor }) => {
        const html = editor.getHTML();
        this.setState({ termsAndConditionsValue: html })
      },
      onSelectionUpdate: ({ editor }) => {
        this.setState(prev => ({ ...prev, activeEditor: "termsAndCondition" }));
        this.updateToolbarPosition(editor);
      },
      onBlur: ({ editor }) => {
        this.handleTitleBlur(editor);
      }
    });
  }

  handleTitleBlur = (editor: Editor) => {
    if (editor && editor.getText().trim() === "") {
      const defaultTitle = "<p>Untitled</p>";
      editor.commands.setContent(defaultTitle)
    }
  };

  updateToolbarPosition(editor: Editor) {
    const selection = editor.state.selection;
    if (selection && !selection.empty) {
      const { from } = selection;
      const coords = editor.view.coordsAtPos(from);
      this.setState({
        isEditing: true,
        toolbarPosition: {
          top: coords.top + window.scrollY + 40,
          left: coords.left,
        },
      });
    } else {
      this.setState({ isEditing: false });
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  componentWillUnmount(): Promise<void> {
    return new Promise((resolve) => {
      // Your cleanup logic here, if needed
      console.log('Component will unmount');

      // Resolve the promise immediately
      resolve();
    });
  }

  postTermsAndConditions = async () => {
    this.setState({ loading: true })
    this.termsAndConditionsApiCallId = await apiCall({
      contentType: "application/json",
      method: "PATCH",
      token: localStorage.getItem("token"),
      endPoint: `bx_block_terms_and_conditions/terms_and_conditions/${this.state.termsCondsList?.id}`,
      body: {
        "terms_and_condition": {
          "description": `${this.state.termsAndConditionsValue}`
        }
      },
    });
  }

  getTermsAndConditions = async () => {
    this.setState({ loading: true })
    this.termsAndConditionsApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_terms_and_conditions/terms_and_conditions/latest_record`,
    });
  }

  getPrivacyPolicy = async () => {
    this.setState({ loading: true })
    this.privacyPolicyApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_terms_and_conditions/privacy_policies/latest_record`,
    });
  }

  postPrivacyPolicy = async () => {
    this.setState({ loading: true })
    this.termsAndConditionsApiCallId = await apiCall({
      contentType: "application/json",
      method: "PATCH",
      token: localStorage.getItem("token"),
      endPoint: `bx_block_terms_and_conditions/privacy_policies/${this.state.privacyPolicyData?.id}`,
      body: {
        "privacy_policy": {
          "description": `${this.state.privacyPolicy}`
        }
      },
    });
  }

  handleClick = (event: any) => {
    if (this.editorRef.current && !this.editorRef.current.contains(event.target)) {
      this.handleClickOutsideEditor();
    }
  };

  handleClickOutsideEditor = () => {
    this.setState({ isEditing: false });
  };

  handleSave = () => {
    const { currentView } = this.state;

    switch (currentView) {
      case 'TermsAndConditions':
      case 'privacyPolicy':
        this.onSaveEditor();
        return;
      case 'helpCentre':
        this.saveRef.current?.handleSave();
        return;
      default:
        return;
    }
  }

  onSaveEditor = () => {
    switch (this.state.activeEditor) {
      case "privacyPolicy":
        this.postPrivacyPolicy()
        break;
      case "termsAndCondition":
        this.postTermsAndConditions()
        break;
      default:
        break;
    }
  }

  handleViewChange = (view: SelectedPage) => {
    if (view === "TermsAndConditions") {
      this.getTermsAndConditions()
    } else if (view === "privacyPolicy") {
      this.getPrivacyPolicy();
    }

    this.setState({ currentView: view })
  }

  updateSaveDisabled = (value: boolean) => {
    this.setState({ isSaveDisabled: value });
  }

  updateLoading = (value: boolean) => {
    this.setState({ loading: value });
    console.log(value);
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.termsAndConditionsApiCallId) {
          if (responseJson.data) {
            const responseData = responseJson.data as ITermsCondsList
            this.setState({ termsCondsList: responseData, termsAndConditionsValue: responseData.attributes.description })
            this.editorTACText?.commands.setContent(responseData.attributes.description)
          } else if (responseJson.error) {
            toast.error(responseJson.error);
          }
        }

        if (apiRequestCallId === this.privacyPolicyApiCallId) {
          if (responseJson.data) {
            const responseData = responseJson.data as IPrivacyPolicyData
            this.setState({ privacyPolicyData: responseData, privacyPolicy: responseData.attributes.description })
            this.editorPPText?.commands.setContent(responseData.attributes.description)
          } else if (responseJson.error) {
            toast.error(responseJson.error);
          }
        }

        this.setState({ loading: false });
      }
    }
    // Customizable Area End
  }
}
