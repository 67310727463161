import React from "react";
import {
    Modal, Box, Button, Typography, DialogTitle, InputBase,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import CloseIcon from '@mui/icons-material/Close';
import { imageArrowLeft } from "./assets";
import CourseCreationHeaderController, { Props } from "./CourseCreationHeaderController";

export default class CourseCreationHeader extends CourseCreationHeaderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderTopBar = () => {
        return (
            <Header data-test-id="wrapper">
                <div className="content">
                    <div className="block">
                        <DefaultCustomButton onClick={this.onBack} style={buttonStyles.backButtonStyle} data-test-id="back-btn">
                            <img src={imageArrowLeft} alt="" />
                            <span>{this.props.isLessonVisible ? "Back" : "Course Dashboard"}</span>
                        </DefaultCustomButton>
                    </div>
                    <div className="block">
                        <DefaultCustomButton data-test-id="requirements-button" onClick={() => this.setState({ requirementsModal: { isVisible: true } })} style={buttonStyles.emptyButtonStyle}>Requirements</DefaultCustomButton>
                        <DefaultCustomButton style={buttonStyles.emptyButtonStyle} onClick={this.props.onPreview} data-test-id="preview-btn">Preview</DefaultCustomButton>
                        <DefaultCustomButton style={buttonStyles.whiteButtonStyle}>Assign Learning Path</DefaultCustomButton>
                        <DefaultCustomButton style={buttonStyles.blackButtonStyle}>Publish</DefaultCustomButton>
                    </div>
                </div>
            </Header>
        );
    }

    renderModalMenu = () => {
        return (
            <Modal
                open={this.state.requirementsModal.isVisible}
                onClose={this.onHideRequirementsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <CourseRequirements>
                    <Formik
                        data-test-id="formik"
                        initialValues={{
                            totalHours: this.state.totalHours,
                            passingPercentage: this.state.passingPercentage,
                            courseRequirements: this.state.courseRequirements,
                        }}
                        enableReinitialize
                        onSubmit={(values) => {
                            this.submitCourseRequirements(values);
                        }}
                    >
                        {({ errors, touched, setFieldValue, handleSubmit }) => (
                            <form
                                noValidate
                                autoComplete="off"
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    handleSubmit()
                                }}
                                className="form"
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                      event.preventDefault();
                                    }
                                  }}
                            >
                                <CourseRequirementsHeader>
                                    <Typography className="title">Course Requirements</Typography>
                                    <CloseIcon className="close-icon" data-test-id="close-btn" onClick={this.onHideRequirementsModal} />
                                </CourseRequirementsHeader>
                                <CourseRequirementsContent>
                                    <Box className="dialog-content">
                                        <Box>
                                            <Typography className="label-title">Total Hours</Typography>
                                            <CustomInput
                                                data-test-id="input-total-hours"
                                                fullWidth
                                                type={"number"}
                                                inputMode="numeric"
                                                onInput={this.handleNumericInput}
                                                placeholder="Total lesson hours"
                                                onChange={(e) => {
                                                    setFieldValue("totalHours", e.target.value.trim());
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography className="label-title">Passing Percentage</Typography>
                                            <CustomInput
                                                data-test-id="input-passing-percentage"
                                                fullWidth
                                                type={"number"}
                                                inputMode="numeric"
                                                onInput={(event) => {this.handleNumericInput(event); this.handleValdidateMaxValue(event)}}
                                                placeholder="Minimum passing percentage"
                                                onChange={(e) => {
                                                    setFieldValue("passingPercentage", e.target.value.trim());
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography className="label-title">Course Requirements</Typography>
                                            <CustomInput
                                                fullWidth
                                                data-test-id="input-course-requirements"
                                                placeholder="Write course requirement here"
                                                onChange={(e) => {
                                                    setFieldValue("courseRequirements", e.target.value.trim());
                                                }}
                                            />
                                        </Box>
                                    </Box>

                                </CourseRequirementsContent>
                                <CourseRequirementsFooter>
                                    <CancelButton onClick={this.onHideRequirementsModal}>Cancel</CancelButton>
                                    <SaveButton data-test-id="submit-button" type="submit">Save</SaveButton>
                                </CourseRequirementsFooter>
                            </form>
                        )}
                    </Formik>
                </CourseRequirements>
            </Modal>
        );
    }

    render() {
        return (
            <>
                {this.renderModalMenu()}
                <header>{this.renderTopBar()}</header>
                <main>
                    {this.props.children}
                </main>
            </>
        );
    }
}

// Customizable Area Start

const Header = styled(Box)({
    backgroundColor: "#FFFFFF",
    borderBottom: "1px solid #E7E5E4",
    padding: "20px 43px 20px 24px",
    "& .content": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .block": {
            gap: 16,
            display: "flex",
            alignItems: "center"
        }
    }
})

const SaveButton = styled(Button)({
    padding: "16px 32px 16px 32px",
    borderRadius: "4px",
    background: "#000000",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    cursor: "pointer",
    textTransform: "none",
    "&:hover": {
        background: "#000000",
        color: "#FFFFFF",
    }
})

const CustomInput = styled(InputBase)({
    padding: "10px 14px 10px 14px",
    background: "#FFFFFF",
    height: 48,
    border: "1px solid #9D9D9D",
    borderRadius: "4px",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    "& ::placeholder": {
        fontFamily: "Heebo",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    }
});

const CancelButton = styled(Button)({
    padding: "16px 32px 16px 32px",
    borderRadius: "4px",
    background: "#F0F0F0",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#000000",
    cursor: "pointer",
    textTransform: "none",
    "&:hover": {
        background: "#F0F0F0",
        color: "#000000",
    }
})

const CourseRequirements = styled(Box)({
    width: 600,
    backgroundColor: "#FFFFFF",
    position: 'absolute',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "4px",

})

const CourseRequirementsHeader = styled(DialogTitle)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 40px 12px 40px",
    borderBottom: "1px solid #E2E8F0",
    "& .title": {
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "28px",
        padding: "10px 0",
    }
})

const CourseRequirementsContent = styled(Box)({
    margin: "16px 0",
    padding: "24px 40px 24px 40px",
    "& .dialog-content": {
        display: "flex",
        flexDirection: "column",
        gap: 16,
    },
    "& .label-title": {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "22px",
        color: "#334155",
        marginBottom: "4px"
    }
})

const CourseRequirementsFooter = styled(Box)({
    borderTop: "1px solid #E2E8F0",
    padding: "24px",
    display: "flex",
    justifyContent: "flex-end",
    gap: 24
})

const baseButtonStyle = {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    padding: "6px 16px",
    color: "#1C1C1C",
};

const buttonStyles = {
    emptyButtonStyle: {
        ...baseButtonStyle,
        fontWeight: 700,
    },
    whiteButtonStyle: {
        ...baseButtonStyle,
        borderRadius: "4px",
        border: "1px solid #1C1C1C",
    },
    blackButtonStyle: {
        ...baseButtonStyle,
        fontFamily: "Heebo",
        fontWeight: 400,
        color: "#FFFFFF",
        padding: "6px 10px",
        backgroundColor: "#1C1C1C",
    },
    backButtonStyle: {
        ...baseButtonStyle,
        fontFamily: "Heebo",
        fontWeight: 400,
        fonstSize: "16px",
        lineHeight: "24px",
        gap: 8,
        "& img": {
            width: 24,
            height: 24,
        }
    }
};

const DefaultCustomButton = styled(Button)({
    textTransform: "none",
});

// Customizable Area End