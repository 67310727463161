import React from "react";
// Customizable Area Start
import {
  Box, Button, InputBase, InputAdornment, Typography,
  Accordion, AccordionSummary, AccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpCentreController from "./HelpCenterController.web";
import Header from "../../../components/src/Header.web";
import Contactus from "../../contactus/src/ContactPage.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class HelpCentre extends HelpCentreController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Wrapper data-test-id="wrapper">
        <Loader loading={this.state.loading} />
        <Header
          navigation={this.props.navigation}
          supportpagename="help-center"
          islogin={true}
          isHeader={false}
        >
          <Box padding={{ xs: "32px 20px 25px", sm: "40px 60px" }}>
            <Box className="right-section">
              <Box className="title">
                <Typography className="help-center">Help Centre</Typography>
                <Typography className="contact-us" onClick={this.onContactPageOpen}>Contact Us</Typography>
              </Box>
              <CustomInputBase
                data-test-id="search"
                value={this.state.searchValue}
                placeholder="Search for a question or problem"
                onChange={(e) => this.onSearch(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#94A3B8" }} />
                  </InputAdornment>
                }
                endAdornment={
                  this.state.searchValue &&
                  <InputAdornment position="end" sx={{ cursor: "pointer" }} onClick={this.removeSearchValue}>
                    <ClearIcon sx={{ color: "#94A3B8" }} />
                  </InputAdornment>
                }
              />
            </Box>
            {this.state.searchResultList.length > 0 ?
              this.state.searchResultList.map((item, index: number) => {
                return (
                  <Accordion key={`${index + 1}`}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box className="content-text" dangerouslySetInnerHTML={{ __html: item.attributes.title }} />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="content-text" dangerouslySetInnerHTML={{ __html: item.attributes.content }} />
                    </AccordionDetails>
                  </Accordion>
                );
              }) : (
                <>
                  <Typography className="not-found">{this.state.loading ? "Loading..." : "No any search result found!"}</Typography>
                </>
              )
            }
            <Box className="contact-info">
              <Typography className="need-support">Need Support?</Typography>
              <Typography className="description">We're here to help! If you have any questions, issues, or need assistance, our support team is ready to assist you. Contact us via email, chat, or phone, and we'll get back to you as soon as possible. Your satisfaction is our priority!</Typography>
              <CustomButton data-test-id="contact-btn" onClick={this.onContactPageOpen}>
                Contact Us
              </CustomButton>
            </Box>
          </Box>
        </Header>
        {this.state.isOpen &&
          <Contactus
            id=""
            isUserLogin={true}
            navigation={this.props.navigation}
            onClose={this.onContactPageClose}
          />
        }
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#FAFAFA",
  [theme.breakpoints.down('md')]: {
    height: "100%",
  },
  "& .right-section": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      display: "block",
    }
  },
  "& .title": {
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      marginBottom: "20px",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .help-center": {
      color: "#0F172A",
      fontSize: "30px",
      marginRight: "10px",
      whiteSpace: "nowrap",
      fontFamily: "Heebo-Bold",
    },
    "& .contact-us": {
      display: "none",
      [theme.breakpoints.down('sm')]: {
        display: "block",
        cursor: "pointer",
        color: "#0F172A",
        fontSize: "14px",
        fontFamily: "Heebo-Bold",
      }
    }
  },
  "& .not-found": {
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiAccordion-root": {
    boxShadow: "none",
    margin: "24px 0px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    "& .content-text p, & .content-text ol": {
      margin: "0px"
    },
    [theme.breakpoints.down('sm')]: {
      margin: "8px 0px",
      borderRadius: "0px",
    },
    "&::before": {
      backgroundColor: "#fff",
    },
    "& .MuiAccordionDetails-root": {
      padding: "0px 27px 16px",
    },
    "& .MuiAccordionSummary-root": {
      marginRight: "20px",
      [theme.breakpoints.down('sm')]: {
        marginRight: "0px",
      }
    },
    "& .MuiAccordionSummary-content": {
      margin: "16px 8px",
      "& p": {
        fontSize: "16px",
        fontFamily: "Heebo-Bold",
      }
    }
  },
  "& .contact-info": {
    borderRadius: "8px",
    padding: "16px 24px",
    backgroundColor: "#F0F0F0",
    "& .need-support": {
      fontSize: "30px",
      color: "#0F172A",
      fontFamily: "Heebo-Bold",
      [theme.breakpoints.down('sm')]: {
        fontSize: "24px",
      }
    },
    "& .description": {
      fontSize: "15px",
      color: "#334155",
      fontFamily: "Heebo-Regular"
    }
  }
}));

const CustomInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  maxWidth: "500px",
  borderRadius: "8px",
  padding: "5px 8px",
  backgroundColor: "#fff",
  border: "1px solid #CBD5E1",
  [theme.breakpoints.down('sm')]: {
    maxWidth: "100%",
    marginBottom: "20px"
  }
}));

const CustomButton = styled(Button)({
  height: "44px",
  marginTop: "10px",
  textTransform: "none",
  backgroundColor: "black",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  borderRadius: "4px !important",
  padding: "6px 15px !important",
  "&:hover": {
    backgroundColor: "#1C1C1C",
  },
});
// Customizable Area End