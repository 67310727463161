import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import React from 'react';
import { Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import FontFamily from '@tiptap/extension-font-family';
import FontSize from 'tiptap-extension-font-size';
import Color from '@tiptap/extension-color';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Highlight from '@tiptap/extension-highlight';
import Strike from '@tiptap/extension-strike';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import TextAlign from '@tiptap/extension-text-align';
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { ActiveDropdownId, Card, Position } from "./HelpCenterAdminPartTypes";
export const crypto = require('crypto');

export interface ApiCallInterface {
  contentType?: string,
  method?: string,
  endPoint?: string,
  body?: object
}

// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start
  navigation?: object;
  isSaveDisabled: boolean;
  saveRef: React.RefObject<{ handleSave: () => void }>;
  onSetSaveDisabled: (value: boolean) => void;
  onUpdateLoading: (value: boolean) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  cards: Card[];
  addedCards: Card[],
  deletedCards: Card[],
  modifiedCards: Card[];
  originalCards: Card[];
  activeCardId: string | null;
  isEditing: boolean;
  toolbarPosition: Position;
  activeDropdownId: ActiveDropdownId | null;
  activeEditor: 'title' | 'content' | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HelpCenterAdminPartController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetHelpCenterById: string = "";
  apiPostHelpCenterById: string = "";
  apiPatchHelpCenterByIds: string[] = [];
  apiDeleteHelpCenterById: string = "";
  editorContent: Editor | null = null;
  editorTitle: Editor | null = null;
  editorContentRef = React.createRef<HTMLDivElement>();
  editorTitleRef = React.createRef<HTMLDivElement>();
  toolbarRef = React.createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      cards: [],
      addedCards: [],
      deletedCards: [],
      modifiedCards: [],
      originalCards: [],
      activeCardId: null,
      isEditing: false,
      toolbarPosition: { top: 0, left: 0 },
      activeDropdownId: null,
      activeEditor: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    this.initEditors();

    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    // Customizable Area End
  }

  initEditors() {
    this.editorTitle = new Editor({
      extensions: [
        StarterKit.configure({ heading: { levels: [1] } }),
        TextStyle,
        FontFamily,
        FontSize,
        Color,
        Highlight,
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList,
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
      ],
      content: '',
      onUpdate: ({ editor }) => {
        const html = editor.getHTML();
        this.updateEditorTitle(html);
      },
      onSelectionUpdate: ({ editor }) => {
        this.setState(prev => ({ ...prev, activeEditor: 'title' }));
        this.updateToolbarPosition(editor);
      },
      onBlur: ({ editor }) => {
        this.handleTitleBlur(editor);
      }
    });

    this.editorContent = new Editor({
      extensions: [
        StarterKit,
        TextStyle,
        FontFamily,
        FontSize,
        Color,
        Highlight,
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList,
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
      ],
      content: '',
      onUpdate: ({ editor }) => {
        const html = editor.getHTML();
        this.updateEditorContent(html);
      },
      onSelectionUpdate: ({ editor }) => {
        this.setState(prev => ({ ...prev, activeEditor: 'content' }));
        this.updateToolbarPosition(editor);
      },
      onBlur: ({ editor }) => {
        this.handleContentBlur(editor);
      }
    });
  }

  updateToolbarPosition(editor: Editor) {
    const selection = editor.state.selection;
    if (selection && !selection.empty) {
      const { from } = selection;
      const coords = editor.view.coordsAtPos(from);

      const toolbarWidth = this.toolbarRef.current?.offsetWidth || 0;
      const windowWidth = window.innerWidth;

      let newLeft = coords.left;

      if (((coords.left + toolbarWidth / 2) - 30) > windowWidth) {
        newLeft = windowWidth - (toolbarWidth / 2) - 30;
      }

      if (coords.left < 0) {
        newLeft = 10;
      }

      this.setState({
        isEditing: true,
        toolbarPosition: {
          top: coords.top + window.scrollY + 40,
          left: newLeft,
        },
      });

    } else {
      this.setState({ isEditing: false });
    }
  }

  handleTitleBlur = (editor: Editor) => {
    if (editor && editor.getText().trim() === "") {
      const defaultTitle = "<p>Untitled</p>";
      editor.commands.setContent(defaultTitle)
      this.updateEditorTitle(defaultTitle);
    }
  };

  handleContentBlur = (editor: Editor) => {
    if (editor && editor.getText().trim() === "") {
      const defaultContent = "<p>Enter your content here</p>";
      editor.commands.setContent(defaultContent);
      this.updateEditorContent(defaultContent);
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.apiGetHelpCenterById) {
          this.getHelpCenterByIdSuccesscallCallback(responseJson.data);
        }
        if (apiRequestCallId === this.apiPostHelpCenterById) {
          this.addHelpCenterDataSuccesscallCallback();
        }
        if (this.apiPatchHelpCenterByIds.includes(apiRequestCallId)) {
          this.updateHelpCenterDataSuccesscallCallback(responseJson.data);
        }
        if (apiRequestCallId === this.apiDeleteHelpCenterById) {
          this.deleteHelpCenterDataSuccesscallCallback();
        }
        this.props.onUpdateLoading(false);
      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.apiGetHelpCenterById) {
          this.getHelpCenterByIdFailureCallback();
        }
        if (apiRequestCallId === this.apiPostHelpCenterById) {
          this.addHelpCenterDataFailureCallback();
        }
        if (this.apiPatchHelpCenterByIds.includes(apiRequestCallId)) {
          this.updateHelpCenterDataFailureCallback();
        }
        if (apiRequestCallId === this.apiDeleteHelpCenterById) {
          this.deleteHelpCenterDataFailureCallback();
        }
        this.props.onUpdateLoading(false);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getHelpCenterByIdApi();
    const saveRef = this.props.saveRef.current as { handleSave: () => void };
    saveRef.handleSave = this.handleSave;

    window.addEventListener('mousedown', this.handleOutsideClick);
  }

  async componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleOutsideClick);
    this.editorTitle?.destroy();
    this.editorContent?.destroy();
  }

  handleOutsideClick = (event: MouseEvent) => {
    if (!this.editorContentRef.current?.contains(event.target as Node)
      || !this.toolbarRef.current?.contains(event.target as Node)) {
      this.setState({ activeEditor: null, isEditing: false });
    }
  }

  handleSave = async () => {
    const { addedCards, modifiedCards, deletedCards } = this.state;

    try {
      for (const card of addedCards) {
        await this.addHelpCenterData(card);
      }

      for (const card of modifiedCards) {
        if (!addedCards.some(addedCard => addedCard.id === card.id)) {
          await this.updateHelpCenterData(card);
        }
      }

      for (const card of deletedCards) {
        await this.deleteHelpCenterData(card.id);
      }

      this.setState({
        addedCards: [],
        modifiedCards: [],
        deletedCards: [],
        activeCardId: null,
        isEditing: false,
      });

      this.props.onSetSaveDisabled(true);
    } catch { }
  }

  getHelpCenterByIdApi = async () => {
    this.props.onUpdateLoading(true);
    this.apiGetHelpCenterById = await this.apiCall({
      contentType: configJSON.helpCenterByIdApiContentType,
      method: configJSON.getHelpCenterByMethod,
      endPoint: configJSON.getHelpCenterByIdUrl,
    });
  };

  addHelpCenterData = async (body: Card) => {
    this.props.onUpdateLoading(true);
    this.apiPostHelpCenterById = await this.apiCall({
      contentType: configJSON.helpCenterByIdApiContentType,
      method: configJSON.postHelpCenterByMethod,
      endPoint: configJSON.getHelpCenterByIdUrl,
      body: {
        id: body.id,
        title: body.attributes.title,
        content: body.attributes.content,
      },
    });
  };

  addHelpCenterDataSuccesscallCallback = async () => {
    if (this.state.deletedCards.length === 0) {
      await this.getHelpCenterByIdApi();
    }
  };

  addHelpCenterDataFailureCallback = () => {

  };

  updateHelpCenterData = async (card: Card) => {
    this.props.onUpdateLoading(true);
    const apiRequestId = await this.apiCall({
      contentType: configJSON.helpCenterByIdApiContentType,
      method: configJSON.updateHelpCenterByMethod,
      endPoint: `${configJSON.getHelpCenterByIdUrl}/${card.id}`,
      body: { title: card.attributes.title, content: card.attributes.content },
    });
    this.apiPatchHelpCenterByIds.push(apiRequestId);
  };

  updateHelpCenterDataSuccesscallCallback = async (data: Card) => {
    await this.getHelpCenterByIdApi();
  };

  updateHelpCenterDataFailureCallback = () => {

  };

  deleteHelpCenterData = async (cardId: string) => {
    this.props.onUpdateLoading(true);
    this.apiDeleteHelpCenterById = await this.apiCall({
      contentType: configJSON.helpCenterByIdApiContentType,
      method: configJSON.deleteHelpCenterByMethod,
      endPoint: `${configJSON.getHelpCenterByIdUrl}/${cardId}`,
    });
  };

  deleteHelpCenterDataSuccesscallCallback = async () => {
    await this.getHelpCenterByIdApi();
  };

  deleteHelpCenterDataFailureCallback = () => {

  };

  apiCall = async (data: ApiCallInterface) => {

    const { contentType, method, endPoint, body } = data;

    let token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getHelpCenterByIdSuccesscallCallback = async (data: Card[]) => {
    this.setState(prev => ({ ...prev, cards: data, originalCards: JSON.parse(JSON.stringify(data)) }));
  };

  getHelpCenterByIdFailureCallback = () => {
    this.setState(prev => ({
      ...prev,
      cards: [],
    }))
  };

  updateCardAttribute = (attributeName: 'content' | 'title', attributeValue: string) => {
    const { activeCardId, cards, addedCards, originalCards } = this.state;

    if (activeCardId !== null) {
      const updatedCards = cards.map(card =>
        card.id === activeCardId
          ? { ...card, attributes: { ...card.attributes, [attributeName]: attributeValue } }
          : card
      );

      this.setState({ cards: updatedCards }, () => {
        const originalCard = originalCards.find(card => card.id === activeCardId);
        const updatedCard = updatedCards.find(card => card.id === activeCardId);

        const isLocallyAdded = addedCards.some(card => card.id === activeCardId);

        if (updatedCard) {
          if (isLocallyAdded) {
            this.setState(prevState => ({
              addedCards: prevState.addedCards.map(card =>
                card.id === activeCardId ? updatedCard : card
              ),
            }));
            this.props.onSetSaveDisabled(this.isSaveButtonDisabled());
          } else {
            const isModified = originalCard &&
              JSON.stringify(originalCard.attributes[attributeName]) !== JSON.stringify(updatedCard.attributes[attributeName]);

            if (isModified) {
              this.setState(prevState => ({
                modifiedCards: prevState.modifiedCards.some(card => card.id === activeCardId)
                  ? prevState.modifiedCards.map(card => card.id === activeCardId ? updatedCard : card)
                  : [...prevState.modifiedCards, updatedCard],
              }));
              this.props.onSetSaveDisabled(this.isSaveButtonDisabled());
            } else {
              this.setState(prevState => ({
                modifiedCards: prevState.modifiedCards.filter(card => card.id !== activeCardId),
              }));
              this.props.onSetSaveDisabled(this.isSaveButtonDisabled());
            }
          }
        }
      });
    }
  };

  updateEditorContent = (content: string) => {
    this.updateCardAttribute('content', content);
  };

  updateEditorTitle = (title: string) => {
    this.updateCardAttribute('title', title);
  };

  handleCardClick = (cardId: string) => {
    if (cardId === this.state.activeCardId) {
      this.setState({ activeCardId: null, isEditing: false });
    } else {
      const selectedCard = this.state.cards.find(card => card.id === cardId);
      if (selectedCard) {
        this.setState({ activeCardId: cardId }, () => {
          if (this.editorContent) {
            this.editorContent.commands.setContent(selectedCard.attributes.content);
            this.editorTitle?.commands.setContent(selectedCard.attributes.title);
            this.editorContent.commands.focus();
          }
        });
      }
    }
  };

  addFAQ = () => {
    const newCardId = this.state.cards.length > 0 ? Math.max(...this.state.cards.map(card => +card.id)) + 1 : 1;
    const newCard: Card = {
      id: `${newCardId}`,
      type: 'help_center',
      attributes: {
        title: 'Untitled',
        content: 'Enter your content here',
        created_at: new Date(),
        updated_at: new Date(),
      }
    };
    this.setState((prevState) => ({
      cards: [...prevState.cards, newCard],
      addedCards: [...prevState.addedCards, newCard],
      modifiedCards: [...prevState.modifiedCards, newCard],
    }), () => {
      this.props.onSetSaveDisabled(this.isSaveButtonDisabled());
    });
  };

  removeFAQ = (cardId: string) => {
    this.setState((prevState) => {
      const cardToDelete = prevState.cards.find(card => card.id === cardId) as Card;

      let newAddedCards = prevState.addedCards.filter(card => card.id !== cardId);
      let newDeletedCards = [...prevState.deletedCards];

      if (!prevState.addedCards.some(card => card.id === cardId)) {
        newDeletedCards = [...prevState.deletedCards, cardToDelete];
      }

      let newModifiedCards = prevState.modifiedCards.filter(card => card.id !== cardId);

      return {
        cards: prevState.cards.filter(card => card.id !== cardId),
        addedCards: newAddedCards,
        deletedCards: newDeletedCards,
        modifiedCards: newModifiedCards,
        activeCardId: prevState.activeCardId === cardId ? null : prevState.activeCardId,
        isEditing: prevState.activeCardId === cardId ? false : prevState.isEditing,
      };
    }, () => {
      this.props.onSetSaveDisabled(this.isSaveButtonDisabled());
    });
  };

  isSaveButtonDisabled = () => {
    const { cards, originalCards } = this.state;

    const hasChanges = JSON.stringify(cards) !== JSON.stringify(originalCards);

    return !hasChanges;
  };
  // Customizable Area End
}