import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEditing:boolean,
  editingType:string,
  editorState:any,
  adminGreetingTitleHTML?: string | any,
  adminGreetingDescriptionHTML?: string | any,
  providerLogoHTML?: string | any,
  providerDescriptionHTML?: string | any
  selectedBlock: any|null,
  previousHTML: {
    adminGreetingTitle:string|any,
    adminGreetingDescription:string|any,
    providerLogo:string|any,
    providerDescription:string|any,
  }
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    ];

    this.state = {
      isEditing: false,
      editingType: '',
      editorState: null,
      selectedBlock: null,
      previousHTML: {
        adminGreetingTitle: '',
        adminGreetingDescription: '',
        providerLogo: '',
        providerDescription: '',
      },
      adminGreetingTitleHTML: null,
      adminGreetingDescriptionHTML: null,
      providerLogoHTML: null,
      providerDescriptionHTML: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
