import React from "react";

import { 
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import {
  StyledEngineProvider,
  styled
} from '@mui/material/styles';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { 
  imgDashboard,
  imgCourses,
  imgPackages,
  imgContentManagement,
  imgPhone,
  imgUser,
  imgClients,
  imgAnalytics,
  imgThinkspikeLogo,
  imgLogout,
  imgChevronRight,
  imgWave,
  imgUXCourses,
  img3Dots,
  imgEdit,
  imgPaintBacket,
  imgTrashBin
} from './assets';
import LandingPageController, {
  Props
} from "./LandingPageController";
import HtmlReactParser from 'html-react-parser';


const editorModules = {
  toolbar: [
    [{ 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link', 'image'],
    [{ 'color': [] }, { 'background': [] }],
    ['clean']
  ],
};
const editorFormats = [
  'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'color', 'background'
];

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditing: false,
      editingType: '',
      editorState: '',
      selectedBlock: null,
      previousHTML: {
        adminGreetingTitle: '',
        adminGreetingDescription: '',
        providerLogo: '',
        providerDescription: '',
      }
    }
  }
  private adminGreetingTitle = React.createRef<HTMLDivElement>();
  private adminGreetingDescription = React.createRef<HTMLDivElement>();
  private providerLogo = React.createRef<HTMLDivElement>();
  private providerDescription = React.createRef<HTMLDivElement>();

  handlePageClick = (e:any) => {
    if (e.target == this.state.selectedBlock || !this.state.selectedBlock) return;
    
    this.setState((prevState) => ({ ...prevState, selectedBlock: null }));
    this.setState((prevState) => ({ ...prevState, editorState: '' }));
    this.setState({isEditing:false});
  }

  setSelectedBlock = (e:any|null) => {
    e?.preventDefault();
    
    const currentTarget = e.currentTarget;
    
    this.setState((prevState) => ({ ...prevState, selectedBlock: currentTarget }));

    this.setState((prevState) => ({ 
      ...prevState, editorState: prevState.selectedBlock?.innerHTML 
    }));
  }

  startEdit = (editingType:string) => {
    this.setState({ 
      previousHTML: {
        adminGreetingTitle: this.adminGreetingTitle.current?.innerHTML,
        adminGreetingDescription: this.adminGreetingDescription.current?.innerHTML,
        providerLogo: this.providerLogo.current?.innerHTML,
        providerDescription: this.providerDescription.current?.innerHTML,
      }
    });

    this.setState({ editingType });

    this.setState({ isEditing: true });
  }

  remove = () => {
    if (!this.state.selectedBlock) return;
    
    this.state.selectedBlock.innerHTML = '';
    this.setState({ selectedBlock: null });
    this.setState({ editorState: '' });
  }

  save = () => {
    if (
      !this.adminGreetingTitle.current ||
      !this.adminGreetingDescription.current ||
      !this.providerLogo.current ||
      !this.providerDescription.current
    ) return;
    this.adminGreetingTitle.current.innerHTML = this.state.previousHTML.adminGreetingTitle;
    this.adminGreetingDescription.current.innerHTML = this.state.previousHTML.adminGreetingDescription;
    this.providerLogo.current.innerHTML = this.state.previousHTML.providerLogo;
    this.providerDescription.current.innerHTML = this.state.previousHTML.providerDescription;
  }

  
  handleEditorChange = (editorState: any, type: string = '') => {
    this.setState({ editorState });
    switch(type){
      case 'admin_greeting_title': this.setState({adminGreetingTitleHTML: editorState});
      break;
      case 'admin_greeting_desc': this.setState({adminGreetingDescriptionHTML: editorState});
      break;
      case 'provider_logo': this.setState({providerLogoHTML: editorState});
      break;
      case 'provider_desc': this.setState({providerDescriptionHTML: editorState});
      break;
      default:
    }
  }

  isEditingRender(condition:any) {
    return (
      condition &&
      <>
      <Button 
        sx={styles.saveButton}
      >
        Save
      </Button>
      <Button sx={styles.moreButton} color="info">
        <img src={img3Dots} alt="3dots" />
      </Button>
      </>
    )
  }
  PopupMenuRender = (condition:boolean) => {
    return (
      condition &&
        <Box className="popup_edit">
          <Button onClick={() => this.startEdit('full')}>
            <img src={imgEdit} alt="pencil" />
          </Button>
          <Button onClick={() => this.startEdit('color')}>
            <img src={imgPaintBacket} alt="color" />
          </Button>
          <Button onClick={this.remove}>
            <img src={imgTrashBin} alt="delete" />
          </Button>
        </Box>
    )
  }
  ReactQuillRender = (condition: boolean, editorState: any, type: string = '') => {
    return (
      condition &&
      <ReactQuill
        value={editorState}
        className={'editor-container'}
        onChange={(e)=>this.handleEditorChange(e, type)}
        modules={editorModules}
        formats={editorFormats}
      />
    )
  }

  render() {
    const { editorState, isEditing, selectedBlock } = this.state;
    const coursesList: any[] = [{ id: 1, name: 'test_a' }, { id: 2, name: 'test_b' }, { id: 3, name: 'test_c' }, { id: 4, name: 'test_d' }];
    const isAdminGreetingTitle = selectedBlock == this.adminGreetingTitle.current;
    const isAdminGreetingDescription = selectedBlock == this.adminGreetingDescription.current;
    const isProviderLogo = selectedBlock == this.providerLogo.current;
    const isProviderDescription = selectedBlock == this.providerDescription.current;
    const isAdminGreetingTitleEditing = isAdminGreetingTitle && isEditing;
    const isAdminGreetingDescriptionEditing = isAdminGreetingDescription && isEditing;
    const isProviderLogoEditing = isProviderLogo && isEditing;
    const isProviderDescriptionEditing = isProviderDescription && isEditing;
    const isShowUpdatedBranding = (template:any) => template && !isEditing;

    return (
      <StyledEngineProvider>
        <Wrapper onClick={this.handlePageClick} data-testid="mainWrapper">
          <Box sx={styles.mainBlock}>
            <Box sx={styles.sideBar}>
              <Box sx={styles.sideBarTop}>
                <Box>
                  <img src={imgThinkspikeLogo} alt="thinks_logo" />
                </Box>

                <List sx={styles.sideNavList}>
                  <ListItemButton sx={styles.sideNavItem} color="inherit">
                    <ListItemIcon sx={styles.iconsStyle}>
                      <img src={imgDashboard} alt="dashBoard" />
                    </ListItemIcon>

                    <ListItemText sx={styles.textRow} primary="Dashboard"/>
                  </ListItemButton>

                  <ListItemButton sx={styles.sideNavItem}>
                    <ListItemIcon sx={styles.iconsStyle}>
                      <img src={imgCourses} alt="courses" />
                    </ListItemIcon>

                    <ListItemText sx={styles.textRow} primary="Courses"/>
                  </ListItemButton>

                  <ListItemButton sx={styles.sideNavItem}>
                    <ListItemIcon sx={styles.iconsStyle}>
                      <img src={imgPackages} alt="pkgs" />
                    </ListItemIcon>

                    <ListItemText sx={styles.textRow} primary="Packages"/>
                  </ListItemButton>

                  <ListItemButton sx={{...styles.sideNavItem, ...styles.blackButton}}>
                    <ListItemIcon sx={styles.iconsStyle}>
                      <img src={imgContentManagement} alt="CM" />
                    </ListItemIcon>

                    <ListItemText sx={styles.textRow} primary="Content Managment"/>
                  </ListItemButton>

                  <ListItemButton sx={styles.sideNavItem}>
                    <ListItemIcon sx={styles.iconsStyle}>
                      <img src={imgPhone} alt="phone" />
                    </ListItemIcon>

                    <ListItemText sx={styles.textRow} primary="Contact us Queries"/>
                  </ListItemButton>

                  <ListItemButton sx={styles.sideNavItem}>
                    <ListItemIcon sx={styles.iconsStyle}>
                      <img src={imgUser} alt="user" />
                    </ListItemIcon>

                    <ListItemText sx={styles.textRow} primary="User management"/>
                  </ListItemButton>

                  <ListItemButton sx={styles.sideNavItem}>
                    <ListItemIcon sx={styles.iconsStyle}>
                      <img src={imgClients} alt="clients" /> 
                    </ListItemIcon>

                    <ListItemText sx={styles.textRow} primary="Clients"/>
                  </ListItemButton>

                  <ListItemButton sx={styles.sideNavItem}>
                    <ListItemIcon sx={styles.iconsStyle}>
                      <img src={imgAnalytics} alt="analytics" />
                    </ListItemIcon>

                    <ListItemText sx={styles.textRow} primary="Analytics"/>
                  </ListItemButton>
                </List>
              </Box>

              <Box 
                sx={{...flexRow, padding: '4px 16px'}}
                data-test-id="logoutButton"
              >
                <Button sx={styles.logoutButton}>
                  <img src={imgLogout} alt="logout" />
                  <span>Logout</span>
                </Button>
              </Box>
            </Box>
            
            <Box sx={styles.pageContent} data-testid="pageContent">
              <Box sx={styles.contentHeader}>
                <Box sx={styles.navigation}>
                  <Box sx={styles.breadcrumbButton}>Content Management</Box>
                  <img src={imgChevronRight} alt="rightangle" />
                  <Box sx={styles.currentNavigationPage}>Landing Page</Box>
                </Box>

                <Box sx={styles.leftActions}>
                  {this.isEditingRender(isEditing)}
                </Box>
              </Box>
              
              <Box sx={styles.userContent}>
                <Box sx={styles.greetingBlock} data-testid="greetingBlock">
                  <Box onClick={(e) => e.stopPropagation()}>
                    {this.PopupMenuRender(isAdminGreetingTitle)}
                    {this.ReactQuillRender(isAdminGreetingTitleEditing, editorState, 'admin_greeting_title')}
                    <Box 
                      sx={styles.adminGreeting} 
                      className={isAdminGreetingTitleEditing? 'hide': ''}
                      onContextMenu={this.setSelectedBlock}
                      onClick={this.setSelectedBlock}
                      ref={this.adminGreetingTitle}
                      data-testid="greetingTitle"
                    >
                      {isShowUpdatedBranding(this.state.adminGreetingTitleHTML) ?
                       <> {HtmlReactParser(this.state.adminGreetingTitleHTML)}</>
                         : <Box sx={styles.greetingBg}>
                         <Box> <img src={imgWave} alt="wave" /></Box>
                            <Box><div>Welcome Damodar, from Builder.Ai</div></Box>
                          </Box>}
                    </Box>  

                    {this.PopupMenuRender(isAdminGreetingDescription)}
                    {this.ReactQuillRender(isAdminGreetingDescriptionEditing, editorState, 'admin_greeting_desc')}
                    <Box
                      sx={styles.greetingInfo}
                      className={isAdminGreetingDescriptionEditing ? 'hide' : ''}
                      onContextMenu={this.setSelectedBlock}
                      onClick={this.setSelectedBlock}
                      ref={this.adminGreetingDescription}
                      data-testid="greetingDescription"
                    >
                      {isShowUpdatedBranding(this.state.adminGreetingDescriptionHTML) ?
                        <Box> {HtmlReactParser(this.state.adminGreetingDescriptionHTML)}</Box> :
                        <p>Here you can access courses designed to enhance your skills and knowledge.
                          Explore the wide range of learning paths we have curated just for you."</p>
                      }
                    </Box>
                  </Box>

                  <Box sx={styles.providerBlock} onClick={(e) => e.stopPropagation()} >
                    {this.PopupMenuRender(isProviderLogo)}
                    {this.ReactQuillRender(isProviderLogoEditing, editorState , 'provider_logo')}
                    <Box 
                      className={isProviderLogoEditing ? 'hide': ''}
                      onContextMenu={this.setSelectedBlock}
                      onClick={this.setSelectedBlock}
                      ref={this.providerLogo}
                      data-testid='providerLogo'
                    >
                      {isShowUpdatedBranding(this.state.providerLogoHTML) ? HtmlReactParser(this.state.providerLogoHTML):
                      <img src="/builderLogo.svg" alt="builder_logo" />}
                    </Box>
                    {this.PopupMenuRender(isProviderDescription)}
                    {this.ReactQuillRender(isProviderDescriptionEditing, editorState , 'provider_desc')}
                    <Box 
                      className={isProviderDescriptionEditing ? 'hide': ''}
                      onContextMenu={this.setSelectedBlock}
                      onClick={this.setSelectedBlock}
                      ref={this.providerDescription}
                      data-testid="providerDescription"
                    >{isShowUpdatedBranding(this.state.providerDescriptionHTML) ?
                      <Box> {HtmlReactParser(this.state.providerDescriptionHTML)}</Box> :
                      <p>"This portal is brought to you in 
                      collaboration with Builder.ai and Join 
                      Talent, tailored for your learning needs."</p>}
                    </Box>
                  </Box>
                </Box>

                <Box sx={styles.pathTitle}>Your Learning Paths</Box>
                <Box sx={styles.filterButtonsBlock}>
                  <Button sx={{ ...styles.blackButton, ...styles.filterButton }}>
                    All Courses {'(' + coursesList.length + ')'}
                  </Button>
                  <Button sx={{
                    color: 'black',
                    backgroundColor: 'white',
                    textTransform: 'none',
                    ...styles.filterButton
                  }} color="info">Experience Design (4)</Button>
                  <Button sx={{
                    color: 'black',
                    backgroundColor: 'white',
                    textTransform: 'none',
                    ...styles.filterButton
                  }} color="info">Interface Design (6)</Button>
                </Box>
                <Box sx={styles.courseCardsBlock}>
                  {coursesList.map((course, index) => {
                    return (<Box sx={styles.courseCard}>
                      <Box>
                        <img src={imgUXCourses} alt="ux_courses" />
                      </Box>
                      <Box sx={styles.courseCardDescription}>
                        <Box>Start the UX Design Process: Empathize, Define, and Ideate </Box>
                        <Box>15 lessons</Box>
                      </Box>
                    </Box>)
                  })
                  }
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={styles.footerBlock}>
            <Box sx={styles.leftFooter}>
              <Box>Terms and Conditions</Box>
              <Box>Pravicy Policy</Box>
              <Box></Box>
            </Box>
            
            <Box>
              <Box>© 2024 All Rights Reserved</Box>
            </Box>
          </Box>
        </Wrapper>
      </StyledEngineProvider>
    )
  }
}

const Wrapper = styled(Box)(({ theme }) => ({
  ".ql-size-huge": {
    fontSize: "32px"
  },
  ".ql-size-large": {
    fontSize: '24px'
  },
  '.hide': {
    display: 'none'
  },
  "p": {
    margin: '0px',
  },
  ".popup_edit": {
    display: 'flex',
    flexDirection: 'row',
    background: '#FFFFFF',
    width:'270px',
    borderRadius:'4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  ".editor-container":{
      position:'absolute',
      zIndex:10,
      background: '#FFFFFF',
      borderRadius:'4px',
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  }
}));

const styles = {
  mainBlock: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor:'#f6f6f6'
  },

  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff'
  },
  leftFooter: {
    display: 'flex',
    gap: '24px'
  },
  rightFooter: {},

  sideBar: {
    height: '974px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '30px 16px 24px 40px',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff'
  },
  sideBarTop: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  sideNavList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },
  sideNavItem: {
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    padding: '4px 16px',
    borderRadius: '8px'
  },
  logoutButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    color: 'red',
    flexGrow: '1',
    justifyContent: 'start',
    fontSize: '16px',
    textTransform: 'none'
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 48px'
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px'
  },
  currentNavigationPage: {
    fontWeight: '700',
    fontSize: '24px',
    padding: '4px 8px'
  },
  breadcrumbButton: {
    fontSize: '24px',
    padding: '4px 8px'
  },
  leftActions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    fontSize: '24px'
  },
  blackButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    textTransform: 'none'
  },
  saveButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    height: '44px',
    padding: '10px 16px 6px',
    fontWeight: '700'
  },
  moreButton: {
    color: 'black',
    padding: '10px'
  },

  userContent: {
    marginLeft: '40px',
    position:'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '26px'
  },
  greetingBlock: {
    display: 'flex',
    flexDirection: 'row',
    padding: '24px 16px',
    marginRight: '48px',
    background: '#FFFFFF'
  },
  adminGreeting: {
    background: '#F0F0F0',
    display: 'inline-block',
    fontSize: '32px',
    whiteSpace: 'wrap',
    marginBottom:'16px',
    padding:'4px',
    borderRadius:'4px'
  },
  greetingBg:{
    background: '#F0F0F0',
    paddingRight:'16px',
    display:'flex',
    flexDirection:'row',
    borderRadius:'4px'
  },
  greetingInfo:{
    display:'flex',
    paddingLeft:'4px',
    flexDirection:'column'
  },
  coursesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  providerBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width:'290px',
    marginLeft:'16px'
  },
  editorContainer: {
    position: 'absolute',
    background: '#FFFFFF',
    borderRadius:'4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  pathTitle: {
    fontSize: '24px',
    fontWeight: '500'
  },

  filterButtonsBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px'
  },
  filterButton: {
    padding: '8px 16px 4px',
    fontSize: '16px'
  },
  courseCardsBlock: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap:'wrap',
    gap: '40px',
    borderRadius: '4px'
  },
  courseCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '240px'
  },
  courseCardDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0px 16px 24px 16px'
  },
  iconsStyle: {
    minWidth: '1px'
  },
  textRow : {
    whiteSpace: 'nowrap'
  }
}

const flexRow = {
  display: 'flex',
  flexDirection: 'row'
}
