// Customizable Area Start
import React, { ReactNode } from "react";
import { createTheme, styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Button,
  Paper,
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import { SketchPicker, ColorResult } from 'react-color';
import {
  undoIcon,
  redoIcon,
  dropdownExpandIcon,
  dropdownExpandActiveIcon,
} from "../assets";
// Customizable Area End

// Customizable Area Start
import EditorModalController, {
  Props,
} from "./EditorModalController";


export type Alignment = 'left' | 'center' | 'right';

export type EditorAction =
    | 'toggleBold'
    | 'toggleItalic'
    | 'toggleUnderline'
    | 'toggleStrike'
    | 'toggleBulletList'
    | 'toggleOrderedList';

const theme = createTheme(
  {
    palette: {
      backgrounds: {
        main: "#FAFAFA",
        light: "#FFFFFF",
        dark: "#000000",
      },
      texts: {
        main: "#0F172A",
        light: "#475569",
        dark: "#334155",
        contrastText: "#1C1C1C",
      },
      danger: {
        main: "#FF7468",
      },
      borders: {
        main: "#D6D3D1",
        light: "#F5F5F5",
      },
    },
  });

const ids = {
  editorModal: 'editor-modal',
  toolbarBtn: 'toolbar-btn',
  toolbarMove: 'toolbar-move',
  dropdown: 'dropdown',
  dropdownItem: 'dropdown-item',
};

const toolbarButtonConfig = [
  {
    icon: <FormatBoldIcon />,
    action: 'toggleBold',
    activeState: 'bold',
    testIdSuffix: 'bold',
  },
  {
    icon: <FormatItalicIcon />,
    action: 'toggleItalic',
    activeState: 'italic',
    testIdSuffix: 'italic',
  },
  {
    icon: <FormatUnderlinedIcon />,
    action: 'toggleUnderline',
    activeState: 'underline',
    testIdSuffix: 'underline',
  },
  {
    icon: <StrikethroughSIcon />,
    action: 'toggleStrike',
    activeState: 'strike',
    testIdSuffix: 'strike',
  },
  {
    icon: <FormatListBulletedIcon />,
    action: 'toggleBulletList',
    activeState: 'bulletList',
    testIdSuffix: 'bulletList',
  },
  {
    icon: <FormatListNumberedIcon />,
    action: 'toggleOrderedList',
    activeState: 'orderedList',
    testIdSuffix: 'orderedList',
  },
];
// Customizable Area End


export default class EditorModal extends EditorModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderEditorToolbarButton = (
    icon: ReactNode,
    action: () => void,
    isActive: boolean,
    dataTestId: string
  ): ReactNode => (
    <EditorButton
      data-test-id={dataTestId}
      bgColor={isActive ? theme.palette.texts.contrastText : theme.palette.backgrounds.light}
      aria-label="editor-button"
      onClick={action}
    >
      {icon}
    </EditorButton>
  );

  EditorDropdownModal = (dropdownId: 'fontFamily' | 'fontSize', initialValue: string, values: string[]) => {
    return (
      <EditorDropdownModal>
        {values.map((value) => (
          <EditorDropdownButton
            key={`${ids.dropdownItem}-${dropdownId}`}
            data-test-id={`${ids.dropdownItem}-${dropdownId}`}
            bgColor={(this.props.editor?.getAttributes('textStyle')[dropdownId] || initialValue) === value ? theme.palette.borders.main : 'transparent'}
            onClick={() => this.handleDropdownItemSelect(dropdownId, value)}
          >
            {value}
          </EditorDropdownButton>
        ))}
      </EditorDropdownModal>
    );
  };

  renderEditorDropdownModal = (dropdownId: 'fontFamily' | 'fontSize', initialValue: string, values: string[]) => (
    this.state.activeDropdownId === dropdownId
      ? this.EditorDropdownModal(dropdownId, initialValue, values)
      : null
  );

  renderEditorDropdown = (dropdownId: 'fontFamily' | 'fontSize', initialValue: string, values: string[]): ReactNode => (
    <EditorDropdownContainer>
      <EditorDropdown data-test-id={`${ids.dropdown}-${dropdownId}`} onClick={() => this.handleDropdownChange(dropdownId)}>
        <EditorDropdownTitle>
          {this.props.editor?.getAttributes('textStyle')[dropdownId] || initialValue}
        </EditorDropdownTitle>
        <EditorDropdownIconContainer bgColor={this.state.activeDropdownId === dropdownId ? theme.palette.texts.contrastText : theme.palette.backgrounds.light}>
          <EditorDropdownIcon
            transform={this.state.activeDropdownId === dropdownId ? 'rotate(-180deg)' : 'none'}
            src={this.state.activeDropdownId === dropdownId ? dropdownExpandActiveIcon : dropdownExpandIcon}
            alt="dropdown-expand-icon"
          />
        </EditorDropdownIconContainer>
      </EditorDropdown>

      {this.renderEditorDropdownModal(dropdownId, initialValue, values)}
    </EditorDropdownContainer>
  );

  EditorDropdownAlignmentModal = () => {
    return (
      <EditorDropdownModal>
        {['left', 'center', 'right'].map((align) => (
          <EditorDropdownButton
            key={`${ids.dropdownItem}-${align}`}
            data-test-id={`${ids.dropdownItem}-textAlign`}
            bgColor={this.props.editor?.isActive({ textAlign: align }) ? theme.palette.borders.main : 'transparent'}
            onClick={() => this.handleDropdownItemSelect('textAlign', align)}
          >
            {this.renderTextAlignIcon(align as Alignment, theme.palette.texts.contrastText)}
          </EditorDropdownButton>
        ))}
      </EditorDropdownModal>
    );
  };

  renderEditorDropdownAlignmentModal = () => this.state.activeDropdownId === 'textAlign' ? this.EditorDropdownAlignmentModal() : null;

  renderEditorDropdownAlignment = () => {
    const currentAlignment = ["left", "center", "right"].find((alignment) =>
      this.props.editor?.isActive({ textAlign: alignment })
    ) as Alignment;

    return (
      <EditorDropdownContainer>
        <EditorDropdown
          data-test-id={`${ids.dropdown}-textAlign`}
          bgColor={theme.palette.texts.contrastText}
          padding="4px"
          onClick={() => this.handleDropdownChange('textAlign')}
        >
          <EditorDropdownTitle>
            {this.renderTextAlignIcon(currentAlignment, theme.palette.backgrounds.light)}
          </EditorDropdownTitle>
          <EditorDropdownIconContainer bgColor={theme.palette.texts.contrastText}>
            <EditorDropdownIcon
              transform={this.state.activeDropdownId === 'textAlign' ? 'rotate(-180deg)' : 'none'}
              src={dropdownExpandActiveIcon}
              alt="dropdown-expand-icon"
            />
          </EditorDropdownIconContainer>
        </EditorDropdown>

        {this.renderEditorDropdownAlignmentModal()}
      </EditorDropdownContainer>
    );
  };

  ColorPicker = () => {
    return (
      <EditorColorPickerContainer>
        <SketchPicker
          data-test-id={`${ids.dropdownItem}-color`}
          color={this.props.editor?.getAttributes('textStyle').color || '#000000'}
          onChangeComplete={(color: ColorResult) => this.handleDropdownItemSelect('color', color.hex)}
        />
      </EditorColorPickerContainer>
    );
  };

  renderColorPicker = () => this.state.activeDropdownId === 'color' ? this.ColorPicker() : null;

  renderEditorColorPicker = () => {
    return (
      <EditorDropdownContainer>
        <EditorDropdownColor data-test-id={`${ids.dropdown}-color`} onClick={() => this.handleDropdownChange('color')}>
          <EditorDropdownColorBlock bgColor={this.props.editor?.getAttributes('textStyle').color || '#000000'} />
          <EditorDropdownIconContainer bgColor={this.state.activeDropdownId === 'color' ? theme.palette.texts.contrastText : theme.palette.backgrounds.light}>
            < EditorDropdownIcon
              transform={this.state.activeDropdownId === 'color' ? 'rotate(-180deg)' : 'none'}
              src={this.state.activeDropdownId === 'color' ? dropdownExpandActiveIcon : dropdownExpandIcon}
              alt="dropdown-expand-icon"
            />
          </EditorDropdownIconContainer>
        </EditorDropdownColor>
        {this.renderColorPicker()}
      </EditorDropdownContainer>
    );
  };

  renderEditorToolbar = () => {
    return toolbarButtonConfig.map(({ icon, action, activeState, testIdSuffix }) =>
      this.renderEditorToolbarButton(
        React.cloneElement(icon, {
          style: {
            width: '20px',
            height: '20px',
            color: this.props.editor?.isActive(activeState)
              ? theme.palette.backgrounds.light
              : theme.palette.texts.contrastText,
          },
        }),
        () => this.props.editor?.chain().focus()[action as EditorAction]().run(),
        !!this.props.editor?.isActive(activeState),
        `${ids.toolbarBtn}-${testIdSuffix}`
      )
    );
  };

  EditorModal = () => {
    return (
      <EditorModalContainer
        data-test-id={ids.editorModal}
        ref={this.props.toolbarRef as React.RefObject<HTMLDivElement>}
        onMouseDown={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.stopPropagation()}
        top={this.props.toolbarPosition.top}
        left={this.props.toolbarPosition.left}
      >
        <Box sx={{ display: 'flex', gap: '4px' }}>
          <EditorActionButton data-test-id={`${ids.toolbarMove}-undo`} onClick={() => this.props.editor?.chain().focus().undo().run()}>
            <EditorActionIcon src={undoIcon} alt="undo" />
          </EditorActionButton>
          <EditorActionButton data-test-id={`${ids.toolbarMove}-redo`} onClick={() => this.props.editor?.chain().focus().redo().run()}>
            <EditorActionIcon src={redoIcon} alt="redo" />
          </EditorActionButton>
        </Box>

        {this.renderEditorDropdown('fontFamily', 'Arial', ['Arial', 'Crimson Text'],)}
        {this.renderEditorDropdown('fontSize', '14px', ['14px', '16px', '18px', '20px'])}
        {this.renderEditorDropdownAlignment()}
        {this.renderEditorColorPicker()}

        {this.renderEditorToolbar()}
      </EditorModalContainer>
    );
  };

  renderEditorModal = () => this.props.isEditing ? this.EditorModal() : null;

  renderTextAlignIcon = (type: Alignment, color: string) => {
    const icons = {
      left: <FormatAlignLeftIcon style={{ width: '20px', height: '20px', color }} />,
      center: <FormatAlignJustifyIcon style={{ width: '20px', height: '20px', color }} />,
      right: <FormatAlignRightIcon style={{ width: '20px', height: '20px', color }} />,
    };
    return icons[type];
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
      this.renderEditorModal()
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const EditorModalContainer = styled(Paper)<{ top: number, left: number }>(({ top, left }) => ({
  position: 'absolute',
  top: `${top}px`,
  left: `${left}px`,
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  zIndex: 1000,
  maxHeight: '28px',
  marginBottom: '2px',
  padding: '12px',
  border: `2px solid ${theme.palette.texts.contrastText}`,
  borderRadius: '0px',
  boxShadow: 'none',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-10px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: `10px solid ${theme.palette.texts.contrastText}`,
  },
}));

const EditorActionButton = styled(Button)({
  color: theme.palette.texts.contrastText,
  border: 'none',
  minWidth: '28px',
  maxHeight: '28px',
  padding: '0px',
  '&:hover': {
    border: 'none',
  },
});

const EditorActionIcon = styled('img')({
  width: '28px',
  height: '28px',
});

const EditorDropdownContainer = styled(Box)({
  position: 'relative',
  maxHeight: '28px',
});

const EditorDropdown = styled(Box)<{ bgColor?: string, padding?: string }>(({ bgColor = 'transparent', padding = '6px 4px' }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding,
  backgroundColor: bgColor,
  gap: '8px',
  borderRadius: '4px',
}));

const EditorDropdownColor = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '1.3px 6px',
  gap: '4px',
});

const EditorDropdownColorBlock = styled(Box)<{ bgColor: string }>(({ bgColor }) => ({
  width: '20px',
  height: '20px',
  borderRadius: '4px',
  backgroundColor: bgColor,
}));

const EditorDropdownTitle = styled(Typography)({
  fontSize: '14px',
  lineHeight: '16px',
});

const EditorDropdownIconContainer = styled(Box)<{ bgColor: string }>(({ bgColor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '16px',
  height: '16px',
  backgroundColor: bgColor,
  borderRadius: '4px',
}));

const EditorDropdownIcon = styled('img')<{ transform: string }>(({ transform }) => ({
  transform
}));

const EditorColorPickerContainer = styled(Box)({
  position: 'absolute',
  top: '120%',
});

const EditorDropdownModal = styled(Box)({
  position: 'absolute',
  top: '120%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 'max-content',
  gap: '5.33px',
  padding: '4px',
  borderRadius: '3px',
  backgroundColor: theme.palette.backgrounds.light,
  boxShadow: '0px 4px 10px -2px #1018281A',
  transform: 'translateX(-50%)',
});

const EditorDropdownButton = styled(Box)<{ bgColor: string }>(({ bgColor }) => ({
  fontSize: '14px',
  backgroundColor: bgColor,
  color: theme.palette.texts.contrastText,
  padding: '2px 6px',
  borderRadius: '4px',
}));

const EditorButton = styled(IconButton)<{ bgColor: string }>(({ bgColor }) => ({
  width: '28px',
  height: '28px',
  borderRadius: '4px',
  backgroundColor: bgColor,
  '&:hover': {
    backgorundColor: bgColor,
  },
}));
// Customizable Area End
