//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import React, { createRef } from 'react';

const defaultContent = {
  "Text": {
    type: "Text",
    paddingTop: 30,
    paddingBottom: 30,
    contentPadding: "70vw",
    heading: `<p style="font-family:'Crimson Text';font-size:24px;font-weight:bold;">Heading</p>`,
    description: `<p style="font-family:'Crimson Text';font-size:20px">At its core, UX design is about enhancing the overall experience a user has while interacting with a product, whether it's a website, an app, or any digital tool. UX design involves understanding the users' needs, behaviors, and motivations to create products that solve real problems in an intuitive way.</p>`,
  },
};
export interface DefaultInputTitle {
  title: string,
  isActiveToChange: boolean
}

export interface LessonItem {
  id: number,
  lessonTitle: DefaultInputTitle,
  isPopUpActive: boolean;
  courseData: any[];
}

export interface LessonCreationBar {
  title: string,
  isActive: boolean,
}

export interface Position {
  x: number,
  y: number
}

export type LessonListType = "bullet" | "numbered" | "empty";
export const crypto = require('crypto');

// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start
  navigation: object;
  idContent: string | number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  description: string;
  lessonCreationBar: LessonCreationBar;
  lessonList: LessonItem[];
  lessonListType: LessonListType;
  courseTitle: string;
  authorName: string
  lessonTitle: string;
  lessonAuthorName: string;
  currentLessonId: number;
  isLessonVisible: boolean;
  isToolbarVisible: boolean;
  isFormatVisible: boolean;
  courseData: any;
  currentObj: any;
  currentIndex: number;
  isDrawerOpen: boolean;
  buttonType: string;
  isSidebarOpen: boolean;
  top: number;
  bottom: number;
  isPreview: boolean;
  draggedItemIndex: null | number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  editorMenuRef: React.RefObject<HTMLDivElement>;
  lessonPopupMenuRef: React.RefObject<HTMLDivElement>;
  lessonTypeMenuRef: React.RefObject<HTMLDivElement>;
  bottomRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      description: "This is the starting of the course description. following are the numbered formatting.",
      lessonCreationBar: { title: "", isActive: true },
      lessonList: [],
      lessonListType: "bullet",
      courseTitle: "",
      authorName: "",
      lessonTitle: "",
      lessonAuthorName: "",
      isInitialLoad: true,
      currentLessonId: -1,
      isLessonVisible: false,
      isToolbarVisible: false,
      courseData: [],
      currentObj: {},
      top: 0,
      bottom: 0,
      currentIndex: -1,
      isDrawerOpen: false,
      isSidebarOpen: false,
      buttonType: "",
      isPreview: false,
      draggedItemIndex: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
        this.editorMenuRef = createRef<HTMLDivElement>();
        this.lessonPopupMenuRefs = {};
        this.lessonTypeMenuRef = createRef<HTMLDivElement>();
        this.bottomRef = createRef<HTMLDivElement>();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  onGoBack = () => {
    let lessonList = [...this.state.lessonList];
    lessonList = lessonList.map((item) => (
      item.id === this.state.currentLessonId
        ? { ...item, courseData: this.state.courseData }
        : item
    ));
    this.setState({
      lessonList,
      courseData: [],
      currentObj: {},
      buttonType: "",
      isPreview: false,
      currentIndex: -1,
      currentLessonId: -1,
      isDrawerOpen: false,
      isSidebarOpen: false,
      isLessonVisible: false,
      isToolbarVisible: false,
    });
  }

  sidebarHandler = () => {
    this.setState({
      currentIndex: this.state.courseData.length -1,
      isSidebarOpen: !this.state.isSidebarOpen,
    });
  }

  openToolbar = () => {
    this.setState({ 
      buttonType: "",
      currentIndex: -1,
      isToolbarVisible: true,
    });
  }

  handleTypeButtonClick = (label: string, isFromSidebar?: boolean) => {
    let courseData = [...this.state.courseData];
    const obj = defaultContent[label];
    if (obj) {
      const isScroll = isFromSidebar && this.state.currentIndex === this.state.courseData.length - 1;
      const index = isFromSidebar ? this.state.currentIndex : this.state.courseData.length - 1;
      courseData.splice(index + 1, 0, obj);
      this.setState({ courseData, currentIndex: -1, isToolbarVisible: false, isSidebarOpen: false }, () => {
        if (isScroll) {
          this.bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }

  handleEditClick = (index: number) => {
    this.setState({ 
      currentIndex: index,
      buttonType: "edit",
      isDrawerOpen: true,
      currentObj: this.state.courseData[index],
    });
  }

  handleFormatClick = (index: number) => {
    if(this.state.buttonType === "format" && this.state.currentIndex === index) {
      this.hideFormatOption();
    } else {
      this.setState({
        currentIndex: index,
        buttonType: "format",
      });
    }
  }

  handleCopyClick = (index: number) => {
    let courseData = [...this.state.courseData];
    let obj = courseData[index];
    courseData.splice(index + 1, 0, obj);
    this.setState({ courseData });
  }

  handleDeleteClick = (index: number) => {
    let courseData = [...this.state.courseData];
    courseData.splice(index, 1);
    this.setState({ courseData });
  }

  hideFormatOption = () => {
    this.setState({ currentIndex: -1, buttonType: "" });
  }

  handlePadding = (e, key: string) => {
    let courseData = [...this.state.courseData];
    let obj = courseData[this.state.currentIndex];
    obj = { ...obj, [key]: e.target.value };
    courseData[this.state.currentIndex] = obj;
    this.setState({ courseData });
  }

  handleBlockPadding = (e) => {
    let courseData = [...this.state.courseData];
    let obj = courseData[this.state.currentIndex];
    const value = parseInt(e.target.value);
    obj = {...obj, paddingTop: value, paddingBottom: value};
    courseData[this.state.currentIndex] = obj;
    this.setState({ courseData });
  }

  handleDrawerToggle = () => {
    const courseData = [...this.state.courseData];
    courseData[this.state.currentIndex] = this.state.currentObj;
    this.setState({
      courseData,
      buttonType: "",
      currentObj: {},
      currentIndex: -1,
      isDrawerOpen: !this.state.isDrawerOpen
    });
  }

  onLessonCreation = (lessonItem: LessonItem, index: number) => {
    this.setState({
      isLessonVisible: true,
      currentLessonId: lessonItem.id,
      lessonTitle: lessonItem.lessonTitle.title,
      lessonAuthorName: this.state.authorName,
      courseData: this.state.lessonList[index].courseData,
    }, () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  onChangeLessonTitle = (event) => {
    let lessonList = [...this.state.lessonList];
    lessonList = lessonList.map((item) => (
      item.id === this.state.currentLessonId
        ? { ...item, lessonTitle: { ...item.lessonTitle, title: event.target.value }}
        : item
    ));
    this.setState({ lessonList, lessonTitle: event.target.value });
  }

  onChangeAuthorName = (event) => {
    this.setState({
      authorName: event.target.value,
      lessonAuthorName: event.target.value,
    });
  }

  onChangeHeading = (value: string) => {
    this.setState({ currentObj: { ...this.state.currentObj, heading: value } });
  }

  onChangeDescription = (value: string) => {
    this.setState({ currentObj: { ...this.state.currentObj, description: value } });
  }

  handleUpDownClick = (index: number) => {
    const courseData = [...this.state.courseData];
    [courseData[index],courseData[index-1]] = [courseData[index-1],courseData[index]];
    this.setState({ courseData });
  }

  onPreview = () => {
    this.setState({
      buttonType: "",
      currentObj: {},
      isToolbarVisible: false,
      isPreview: !this.state.isPreview,
    });
  }

  onPlusClick = (index: number) => {
    this.setState({
      isSidebarOpen: true,
      currentIndex: index
    });
  }

  scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight
    });
  };

  componentDidMount() {
    this.setState({ isInitialLoad: false });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.lessonList.length != this.state.lessonList.length) {
      if (this.state.lessonList.length === 0) {
        this.setState({ ...this.state, lessonCreationBar: { title: "", isActive: true } })
        this.scrollToBottom()
      }
    }

    if (!this.state.isInitialLoad && !prevState.lessonCreationBar.isActive && this.state.lessonCreationBar.isActive) {
      this.scrollToBottom();
    }
  }

  addNewLesson = (title: string) => {
    const generatedId = crypto.randomBytes(16).toString('hex');
    if (this.state.lessonList.some(lesson => lesson.lessonTitle?.title === title)) {
      return;
    }

    this.setState({
      lessonCreationBar: { title: "", isActive: false },
      lessonList: [...this.state.lessonList, { lessonTitle: { title, isActiveToChange: false }, id: generatedId, courseData: [] }],
    });

    this.scrollToBottom()
  };

  deleteLesson = (id: number) => {
    this.setState({ lessonList: [...this.state.lessonList.filter((lesson) => lesson.id != id)] })
  }

  setCreationBarActive = () => {
    this.setState({ lessonCreationBar: { ...this.state.lessonCreationBar, isActive: true } });
  }

  onHandleEnterPress = (event, callback) => {
    if (event.key === 'Enter') {
      callback();
    }
  }

  onToggleLessonTypeMenu = (event: React.FormEvent<HTMLInputElement>) => {
    const { clientX: posX, clientY: posY } = event;
    if (this.lessonTypeMenuRef.current) {
      this.lessonTypeMenuRef.current.openMenu(posX, posY);
    }
  }

  onHandleSetLessonTitleActive = (lessonId, value: boolean) => {
    this.setState(prevState => ({
      lessonList: prevState.lessonList.map(lessonItem =>
        lessonItem.id === lessonId
          ? {
            ...lessonItem,
            lessonTitle: {
              ...lessonItem.lessonTitle,
              isActiveToChange: value
            }
          }
          : lessonItem
      )
    }));
  };

  onHandleLessonTitleChange = (event, lessonId) => {
    this.setState(prevState => ({
      lessonList: prevState.lessonList.map(item =>
        item.id === lessonId
          ? {
            ...item,
            lessonTitle: {
              ...item.lessonTitle,
              title: event.target.value
            }
          }
          : item
      )
    }));
  }

  onHandleCourseTitleChange = (event) => {
    this.setState({ courseTitle: event.target.value });
  }

  onHandleAuthorNameTitleChange = (event) => {
    this.setState({ authorName: event.target.value });
  }

  setLessonListType = (type: LessonListType) => {
    this.setState({ lessonListType: type });
    if (this.lessonTypeMenuRef.current) {
      this.lessonTypeMenuRef.current.closeMenu();
    }
  }

  onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }

    const reorderedList = Array.from(this.state.lessonList);
    const [removed] = reorderedList.splice(source.index, 1);
    reorderedList.splice(destination.index, 0, removed);
    this.setState({ lessonList: reorderedList });
  };

  onOpenLessonPopupMenu = (event: React.FormEvent<HTMLInputElement>, lessonId: number) => {
    const targetElement = event.currentTarget;
    const { x, y, height } = targetElement.getBoundingClientRect();
    if (this.lessonPopupMenuRefs[lessonId] && this.lessonPopupMenuRefs[lessonId].current) {
      this.lessonPopupMenuRefs[lessonId].current.openMenu(x - 180, y + height + 8)
      this.setState(prevState => ({
        lessonList: prevState.lessonList.map(item => ({
          ...item,
          isPopUpActive: item.id === lessonId
        }))
      }));
    }
  };

  onCloseLessonPopupMenu = () => {
    this.setState({
      lessonList: this.state.lessonList.map(item => ({
        ...item,
        isPopUpActive: false
      }))
    });
  }

  onHandleOpenEditorMenu = (event: React.FormEvent<HTMLInputElement>) => {
    const { clientX: posX, clientY: posY } = event;
    if (this.editorMenuRef.current) {
      this.editorMenuRef.current.openMenu(posX, posY);
    }
  }

  onEditorSave = (result: string) => {
    this.setState({description: result});
    if (this.editorMenuRef.current) {
      this.editorMenuRef.current.closeMenu();
    }
  }

  // Customizable Area End
}
