//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
    // Customizable Area Start
    defaultValue: string,
    onSave: (result: string) => {}
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    editorValue: string,
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

// Customizable Area Start

// Customizable Area End


export default class EditorController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            editorValue: this.props.defaultValue,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    onHandleChangeValue = (value: string) => {
        this.setState({ editorValue: value })
    }

    onSubmitEditor = () => {
        this.props.onSave(this.state.editorValue.replace(/^<p>(.*?)<\/p>$/, '$1'))
    }
    // Customizable Area End
}
