import React from 'react';
import { Props } from "react-select";
import {
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { imgThinkspikeLogo, imgDashboard, imgCourses, imgPackages, imgPhone, imgUser, imgClients, imgAnalytics, imgPhoneWhite, imgContentManagementBlack } from "./assets";

export default class Sidebar extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

    }
    render() {
        return (
            <Box sx={styles.sideBar}>
                <Box sx={styles.sideBarTop}>
                    <Box>
                        <img src={imgThinkspikeLogo} alt="thinks_logo" />
                    </Box>

                    <List sx={styles.sideNavList}>
                        <ListItemButton sx={styles.sideNavItem} color="inherit">
                            <ListItemIcon sx={styles.iconsStyle}>
                                <img src={imgDashboard} alt="dashBoard" />
                            </ListItemIcon>

                            <ListItemText sx={styles.textRow} primary="Dashboard" />
                        </ListItemButton>

                        <ListItemButton sx={styles.sideNavItem}>
                            <ListItemIcon sx={styles.iconsStyle}>
                                <img src={imgCourses} alt="courses" />
                            </ListItemIcon>

                            <ListItemText sx={styles.textRow} primary="Courses" />
                        </ListItemButton>

                        <ListItemButton sx={styles.sideNavItem}>
                            <ListItemIcon sx={styles.iconsStyle}>
                                <img src={imgPackages} alt="pkgs" />
                            </ListItemIcon>

                            <ListItemText sx={styles.textRow} primary="Packages" />
                        </ListItemButton>

                        <ListItemButton sx={[{ ...styles.sideNavItem }, this.props.current === 'content_management' && { ...styles.blackButton }]}>
                            <ListItemIcon sx={styles.iconsStyle}>
                                <img src={imgContentManagementBlack} alt="CM" />
                            </ListItemIcon>

                            <ListItemText sx={styles.textRow} primary="Content Managment" />
                        </ListItemButton>

                        <ListItemButton sx={[{ ...styles.sideNavItem }, this.props.current === 'contact_us_queries' && { ...styles.blackButton }]}>
                            <ListItemIcon sx={styles.iconsStyle}>
                                <img src={this.props.current === 'contact_us_queries' ? imgPhoneWhite : imgPhone} alt="phone" />
                            </ListItemIcon>

                            <ListItemText sx={styles.textRow} primary="Contact us Queries" />
                        </ListItemButton>

                        <ListItemButton sx={styles.sideNavItem}>
                            <ListItemIcon sx={styles.iconsStyle}>
                                <img src={imgUser} alt="user" />
                            </ListItemIcon>

                            <ListItemText sx={styles.textRow} primary="User management" />
                        </ListItemButton>

                        <ListItemButton sx={styles.sideNavItem}>
                            <ListItemIcon sx={styles.iconsStyle}>
                                <img src={imgClients} alt="clients" />
                            </ListItemIcon>

                            <ListItemText sx={styles.textRow} primary="Clients" />
                        </ListItemButton>

                        <ListItemButton sx={styles.sideNavItem}>
                            <ListItemIcon sx={styles.iconsStyle}>
                                <img src={imgAnalytics} alt="analytics" />
                            </ListItemIcon>

                            <ListItemText sx={styles.textRow} primary="Analytics" />
                        </ListItemButton>
                    </List>
                </Box>
            </Box>

        )
    }
}

const styles = {
    sideBar: {
        height: '974px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '30px 16px 24px 40px',
        border: '1px solid #f5f5f5',
        backgroundColor: '#fff'
    },
    sideBarTop: {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
    },
    sideNavList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
    },
    sideNavItem: {
        height: '48px',
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        padding: '4px 16px',
        borderRadius: '8px'
    },
    logoutButton: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        color: 'red',
        flexGrow: '1',
        justifyContent: 'start',
        fontSize: '16px',
        textTransform: 'none'
    },
    pageContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    contentHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px 48px'
    },
    navigation: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '16px'
    },
    currentNavigationPage: {
        fontWeight: '700',
        fontSize: '24px',
        padding: '4px 8px'
    },
    breadcrumbButton: {
        fontSize: '24px',
        padding: '4px 8px'
    },
    leftActions: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        fontSize: '24px'
    },
    blackButton: {
        backgroundColor: 'black',
        color: 'white',
        '&:hover': {
            backgroundColor: '#333333',
        },
        textTransform: 'none'
    },
    saveButton: {
        backgroundColor: 'black',
        color: 'white',
        '&:hover': {
            backgroundColor: '#333333',
        },
        height: '44px',
        padding: '10px 16px 6px',
        fontWeight: '700'
    },
    moreButton: {
        color: 'black',
        padding: '10px'
    },

    userContent: {
        marginLeft: '40px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '26px'
    },
    greetingBlock: {
        width: '1069px',
        display: 'flex',
        flexDirection: 'row',
        padding: '24px 16px',
        background: '#FFFFFF'
    },
    adminGreeting: {
        display: 'inline-block',
        fontSize: '32px',
        whiteSpace: 'wrap',
        marginBottom: '16px',
        padding: '4px',
        borderRadius: '4px'
    },
    greetingBg: {
        background: '#F0F0F0',
        paddingRight: '16px',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '4px'
    },
    greetingInfo: {
        paddingLeft: '4px',
    },
    coursesWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    providerBlock: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '290px',
        marginLeft: '16px'
    },
    editorContainer: {
        position: 'absolute',
        background: '#FFFFFF',
        borderRadius: '4px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    pathTitle: {
        fontSize: '24px',
        fontWeight: '500'
    },

    filterButtonsBlock: {
        display: 'flex',
        flexDirection: 'row',
        gap: '24px'
    },
    filterButton: {
        padding: '8px 16px 4px',
        fontSize: '16px'
    },
    courseCardsBlock: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '40px',
        borderRadius: '4px'
    },
    courseCard: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        width: '240px'
    },
    courseCardDescription: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '0px 16px 24px 16px'
    },
    iconsStyle: {
        minWidth: '1px',
    },
    textRow: {
        whiteSpace: 'nowrap'
    }
}

const flexRow = {
    display: 'flex',
    flexDirection: 'row'
}