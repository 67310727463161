// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { apiCall } from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
import { MyContext } from '../../../components/src/NavigationRouteWrapper/Web';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  isAdmin?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  confirmPassword: string;
  isPasswordShow: boolean;
  isConfirmPasswordShow: boolean;
  loading: boolean;
  token: null | string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: string;
  // Customizable Area End
}

export default class PasswordResetController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPasswordApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      password: "",
      confirmPassword: "",
      isPasswordShow: false,
      isConfirmPasswordShow: false,
      loading: false,
      token: null
    };
    PasswordResetController.contextType = MyContext;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.resetPasswordApiCallId) {
          if (responseJson.message) {
            toast.success(responseJson.message);
            const { setAccessPermission } = this.context as any;
            setAccessPermission?.(true);
            this.redirect();
          } else if (responseJson.errors && Array.isArray(responseJson.errors) && responseJson.errors[0]?.token) {
            toast.error(responseJson.errors[0]?.token);
          } else {
            this.handleResponse(responseJson);
          }
          this.setState({ loading: false });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = new URLSearchParams(window.location.search).get('token');
    const authToken = localStorage.getItem("token");
    if (token) {
      this.setState({ token });
    } else if (!token && !authToken) {
      if (this.props.isAdmin) {
        this.props.navigation.navigate("AdminLogin");
      } else {
        this.props.navigation.navigate("EmailAccountRegistration");
      }
    }
  }

  handleResponse = (responseJson: {
    status: number | null | string | undefined;
    error: string | null | undefined;
    errors: { profile: string | null | undefined; } | undefined;
  }) => {
    if (responseJson.errors && Array.isArray(responseJson.errors) && responseJson.errors[0]?.profile) {
      toast.error(responseJson.errors[0]?.profile);
    } else if (responseJson.status === 500) {
      toast.error(responseJson.error);
    }
  }

  redirect = () => {
    this.props.isAdmin ? this.redirectAdmin() : this.redirectUser();
  }

  redirectUser = () => {
    if (this.state.token) {
      this.props.navigation.navigate("EmailAccountRegistration");
    } else {
      this.props.navigation.navigate("LandingPage");
    }
  }

  redirectAdmin = () => {
    if (this.state.token) {
      this.props.navigation.navigate("AdminLogin");
    } else {
      this.props.navigation.navigate("AdminLandingPage");
    }
  }

  onShowPassword = () => {
    this.setState({ isPasswordShow: !this.state.isPasswordShow });
  }

  onShowConfirmPassword = () => {
    this.setState({ isConfirmPasswordShow: !this.state.isConfirmPasswordShow });
  }

  resetPasswordApi = async () => {
    this.setState({ loading: true });
    const token = this.state.token;
    this.resetPasswordApiCallId = await apiCall({
      method: "PATCH",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: token ? undefined : localStorage.getItem("token"),
      endPoint: this.props.isAdmin ? `/bx_block_admin/passwords/reset_password` : `/passwords/reset_password`,
      body: {
        "data": {
          "token": token ? token : undefined,
          "new_password": this.state.password,
          "confirm_password": this.state.confirmPassword,
        }
      }
    });
  }
  // Customizable Area End
}