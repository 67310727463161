import React from "react";
import {
    Box, Button
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditorController, { Props } from "./EditorController";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { upTriangleIcon } from "./assets";

var Size = Quill.import("attributors/style/size");

Size.whitelist = [
    "16px",
    "18px",
    "20px"
];

Quill.register(Size, true);

const QuillModules = {
    toolbar: [
        [{ font: [] }],
        [{ size: Size.whitelist }],
        [{ align: [] }],
        [{ color: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
    },
};

const QuillFormats = [
    'font',
    'size',
    'align',
    'color',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'ordered',
];

export default class Editor extends EditorController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <Wrapper>
                <img className="icon" src={upTriangleIcon}></img>
                <ReactQuill
                    theme="snow"
                    className="description-editor"
                    value={this.state.editorValue}
                    onChange={this.onHandleChangeValue}
                    modules={QuillModules}
                    formats={QuillFormats}
                />
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <SaveButton onClick={this.onSubmitEditor}>Save</SaveButton>
                </div>
            </Wrapper>
        );
    }
}

// Customizable Area Start

const SaveButton = styled(Button)({
    marginTop: 8,
    padding: "8px 16px",
    borderRadius: "4px",
    background: "#000000",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
        background: "#000000",
        color: "#FFFFFF",
    }
})

const Wrapper = styled(Box)({
    display: 'inline-block',
    width: "538px",
    height: "auto",
    "& .description-editor": {
        backgroundColor: "#FFFFFF",
        outline: "2px solid #1C1C1C",
        "& .ql-container": {
            maxHeight: "200px",
            overflow: "auto",
        },
        "& .ql-editor": {
            height: 'auto'
        },
        "& .ql-snow .ql-size .ql-picker-item[data-value='16px']::before": {
            content: "'16'",
            fontSize: "16px !important"
        },
        "& .ql-snow .ql-size .ql-picker-item[data-value='18px']::before": {
            content: "'18'",
            fontSize: "18px !important"
        },
        "& .ql-snow .ql-size .ql-picker-item[data-value='20px']::before": {
            content: "'20'",
            fontSize: "20px !important"
        },
        "& .ql-picker-label[data-value='16px']::before": {
            content: "'16'",
        },
        "& .ql-picker-label[data-value='18px']::before": {
            content: "'18'",
        },
        "& .ql-picker-label[data-value='20px']::before": {
            content: "'20'",
        }
    },
    "& .icon": {
        display: "block",
        margin: "0 auto"
    }
});

// Customizable Area End