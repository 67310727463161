import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
import { Editor } from '@tiptap/react';
import { ActiveDropdownId, Position } from "./HelpCenterAdminPartTypes";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation?: object;
  editor: Editor | null;
  isEditing: boolean;
  toolbarPosition: Position;
  toolbarRef: React.RefObject<HTMLDivElement | unknown>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeItemId: string | null;
  toolbarPosition: Position;
  activeDropdownId: ActiveDropdownId | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EditorToolbarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  //  React.createRef<HTMLDivElement>()
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeItemId: null,
      toolbarPosition: { top: 0, left: 0 },
      activeDropdownId: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  handleDropdownChange = (dropdownId: ActiveDropdownId) => {
    this.setState((prev) => ({
      ...prev,
      activeDropdownId: this.state.activeDropdownId === dropdownId ? null : dropdownId,
    }));
  };

  handleDropdownItemSelect = (dropdownId: ActiveDropdownId, value: string) => {
    if (dropdownId === 'fontSize') {
      this.applyFontSize(value);
      this.setState({ activeDropdownId: null });
    }
    if (dropdownId === 'fontFamily') {
      this.applyFontFamily(value);
      this.setState({ activeDropdownId: null });
    }
    if (dropdownId === 'textAlign') {
      this.applyTextAlign(value);
      this.setState({ activeDropdownId: null });
    }
    if (dropdownId === 'color') {
      this.applyTextColor(value);
    }
  }

  applyFontSize = (size: string) => {
    if (this.props.editor) {
      this.props.editor.chain().focus().setFontSize(size).run();
    }
  };

  applyFontFamily(font: string) {
    if (this.props.editor) {
      this.props.editor.chain().focus().setFontFamily(font).run();
    }
  }

  applyTextAlign(align: string) {
    if (this.props.editor) {
      this.props.editor.chain().focus().setTextAlign(align).run();
    }
  }

  applyTextColor = (color: string) => {
    if (this.props.editor) {
      this.props.editor.chain().focus().setColor(color).run();
    }
  };
  // Customizable Area End
}
