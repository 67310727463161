Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderCourseDescription = "Describe your course";
exports.placeHolderLessonTitle = "Add lesson title here";
exports.labelPressEnterNewLesson = "Press Enter to add new lesson";
exports.labelEditLesson = "Edit lesson";
exports.labelAddLesson = "Add lesson";
exports.labelCreateNewLesson = "Create New Lesson";
exports.labelCreateNewQuiz = "Create New Quiz";
exports.undoIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
<path d="M10.5 3.97003H2.88438L5.12625 1.72878L4.25 0.845032L0.5 4.59503L4.25 8.34503L5.12625 7.46066L2.88625 5.22003H10.5C11.4946 5.22003 12.4484 5.61512 13.1517 6.31838C13.8549 7.02164 14.25 7.97547 14.25 8.97003C14.25 9.96459 13.8549 10.9184 13.1517 11.6217C12.4484 12.3249 11.4946 12.72 10.5 12.72H5.5V13.97H10.5C11.8261 13.97 13.0979 13.4432 14.0355 12.5056C14.9732 11.5679 15.5 10.2961 15.5 8.97003C15.5 7.64395 14.9732 6.37218 14.0355 5.4345C13.0979 4.49682 11.8261 3.97003 10.5 3.97003Z" fill="#212529"/>
</svg>`
exports.redoIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
<path d="M5.5 3.97003H13.1156L10.8737 1.72878L11.75 0.845032L15.5 4.59503L11.75 8.34503L10.8737 7.46066L13.1137 5.22003H5.5C4.50544 5.22003 3.55161 5.61512 2.84835 6.31838C2.14509 7.02164 1.75 7.97547 1.75 8.97003C1.75 9.96459 2.14509 10.9184 2.84835 11.6217C3.55161 12.3249 4.50544 12.72 5.5 12.72H10.5V13.97H5.5C4.17392 13.97 2.90215 13.4432 1.96447 12.5056C1.02678 11.5679 0.5 10.2961 0.5 8.97003C0.5 7.64395 1.02678 6.37218 1.96447 5.4345C2.90215 4.49682 4.17392 3.97003 5.5 3.97003Z" fill="#212529"/>
</svg>`
// Customizable Area End
