import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { AttemptedAssessmentInterface } from "./AssessmentTestPage.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  assessmentList: AttemptedAssessmentInterface[],
  activeAssessmentId: { listItem: number, item: number }
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      assessmentList: [
        {
          id: 1, title: "Introduction", assessments: [
            { id: 1, title: "Introducing user experience design", isPassed: true},
            { id: 2, title: "Start the UX Design Process: Empathize, Define, and Ideate", isPassed: false }
          ]
        },
        {
          id: 2, title: "Dive in the UX", assessments: [
            { id: 1, title: "Build Wireframes and Low-Fidelity Prototypes", isPassed: false },
            { id: 2, title: "Design a User Experience for Social Good & Prepare for Jobs", isPassed: false },
            { id: 3, title: "Design a User Experience for Social Good & Prepare for Jobs", isPassed: false },
            { id: 4, title: "Test Your Knowledge", isPassed: false }
          ]
        }
      ],
      activeAssessmentId: { listItem: 1, item: 1 }
    }
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


// Customizable Area Start

  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    // Customizable Area End
  }

  // Customizable Area Start

  isAssessmentActive = (listItem: number, item: number) => {
    return this.state.activeAssessmentId.listItem === listItem && this.state.activeAssessmentId.item === item
  }

  // Customizable Area End
}
