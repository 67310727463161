export const addImage = require("../assets/image_add_24px.png").default;
export const addImageLight = require("../assets/image_add_light_24px.png").default;
export const trashImage = require("../assets/image_trash_button.png").default;
export const imageListNumbered = require("../assets/image_format_list_numbered_24px.png").default;
export const imageListBulleted = require("../assets/image_format_list_bulleted_24px.png").default;
export const imageListEmpty = require("../assets/image_format_list_empty.png").default;
export const imageArrowRight = require("../assets/image_arrow_right.png").default;
export const imageArrowUp = require("../assets/keyboard_arrow_up_24px.png").default;
export const imageArrowLeft = require("../assets/keyboard_arrow_left_24px.png").default;
export const upTriangleIcon = require("../assets/up_triangle_icon.png").default;
export const textIcon = require('../assets/text.svg').default;
export const listIcon = require('../assets/list.svg').default;
export const imageIcon = require('../assets/image.svg').default;
export const videoIcon = require('../assets/video.svg').default;
export const tableIcon = require('../assets/table.svg').default;
export const flashcardIcon = require('../assets/flashcard.svg').default;
export const carouselIcon = require('../assets/carousel.svg').default;
export const buttonIcon = require('../assets/button.svg').default;
export const plusIcon = require('../assets/plus.svg').default;
export const editIcon = require('../assets/edit.svg').default;
export const formateIcon = require('../assets/format.svg').default;
export const copyIcon = require('../assets/copy.svg').default;
export const deleteIcon = require('../assets/delete.svg').default;
export const dashboardIcon = require("../assets/dashboard.svg").default;